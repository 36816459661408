import { axiosInstanceWithAuth } from '@/utils/utils'
import { ref } from 'vue'

const getQuickMonitoringPriorities = () => {
    const quickMonitoringPriorities = ref(null)
    const error = ref(null)

    const load = async () => {
        try {
            let res = await axiosInstanceWithAuth.get('/api/quick-monitoring-priority')
            quickMonitoringPriorities.value = res.data.map(priority => {
                return { value: priority.id, label: priority.name, color: priority.color }
            })
            console.log('quickMonitoringPriorities: ', quickMonitoringPriorities.value)
        } catch (err) {
            error.value = err
        }
    }

    return { quickMonitoringPriorities, load, error }
}

export default getQuickMonitoringPriorities