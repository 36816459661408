import { defineStore } from 'pinia'

const PunctualMonitoringIndices = {
    IA: 'ia',
    IFR: 'ifr'
}

const TopographicLevels = {
    REGION: 'region',
    INSULA: 'insula',
    UNITY: 'unity',
    ENVIRONMENT: 'environment',
    TOPOGRAPHIC_ELEMENT_4: 'topographic_element_4'
}

export const useMapStore = defineStore({
    id: 'map',
    state: () => ({
        punctualMonitoring: {
            index: PunctualMonitoringIndices.IA,
            topographicLevel: TopographicLevels.UNITY
        },
        currentTopographicReference: null,
        selectedMonitoringType: 'punctual',
        previousSelectedMonitoringType: null,
    })
})
