<template>
  <div class="degrado-popup flex-col gap-20">
    <div class="jc-between ai-center">
      <h2>{{ props.degradation.degradation_type.name }}</h2>
      <VButton @click="$emit('close')" :icon="require('@/assets/icons/cross.svg')" />
    </div>
    <div class="grid degrado-content">
      <div class="flex-col gap-20">
        <div class="jc-between">
          <div class="gap-10">
            <p class="tag rounded ai-center gap-5"><strong>DATA </strong>{{ readableDate }}</p>
            <p class="tag rounded ai-center gap-5"><strong>ORA </strong>{{ readableTime }}</p>
          </div>
          <!-- <VButton @click="edit" class="medium rounded edit-sheet jc-center" :icon="require('@/assets/icons/edit.svg')" /> -->
        </div>
        <div class="flex-col gap-5">
          <p><strong>PARTIZIONE: </strong>{{ props.degradation.degradation_type.specific_elements[0]?.name }}</p>
          <p><strong>DESCRIZIONE DEGRADO: </strong>{{ props.degradation.degradation_type.name }}</p>
          <p><strong>CODICE DEGRADO: </strong>{{ props.degradation.degradation_type.code }}</p>
          <p><strong>GRAVITÀ: </strong>{{ props.degradation.gravity.complete_name }}</p>
          <p><strong>ESTENSIONE: </strong>{{ props.degradation.extension.name }}</p>
          <p><strong>INTENSITÀ: </strong>{{ props.degradation.intensity.name }}</p>
          <p><strong>METRI/METRI QUADRI: </strong>{{ props.degradation.square_meters }}</p>
          <!--<p><strong>SEGNALATORE: </strong></p>-->
          <p><strong>ANNOTAZIONE: </strong>{{ props.degradation.annotations }}</p>
        </div>
        <SheetGallery :images="props.degradation.files" />
      </div>
      <!-- <div class="flex-col gap-20">
        <div class="jc-between">
          <StatusTag label="IA" :color="'#' + props.degradation.IA.color" :border="true" :height="42" />
          <StatusTag label="IFR" :color="'#' + props.degradation.IFR.color" :border="true" :height="42" />
          <StatusTag label="DR" :border="true" :height="42" />
        </div>
        <div class="flex-col gap-10">
          <VButton class="medium rounded action dark" label="Risolvi" />
          <VButton class="medium rounded action dark" label="Cancella" />
        </div>
      </div> -->
    </div>
  </div>
</template>
<script setup>
import { useUXStore } from '@/stores/ux';
import { onMounted, onUnmounted, defineEmits, defineProps } from 'vue'
import SheetGallery from './SheetGallery.vue';
//import StatusTag from './StatusTag.vue';

defineEmits(['close'])
const props = defineProps({ degradation: Object })
const uxstore = useUXStore()

let readableDate = new Date(props.degradation.created_at).toLocaleDateString('it-IT', { day: 'numeric', month: 'numeric', year: 'numeric' })
let readableTime = new Date(props.degradation.created_at).toLocaleTimeString('it-IT', { hour: '2-digit', minute: '2-digit' })

onMounted(() => {
  uxstore.showOverlay = true
})

onUnmounted(() => {
  uxstore.showOverlay = false
})
</script>
<style scoped>
.degrado-popup {
  position: absolute;
  z-index: 10;
  width: 750px;
  padding: 30px;
  align-self: center;
  background: var(--base-light-color);
  left: 50%;
  transform: translateX(-50%);
}

.degrado-content {
  grid-template-columns: 6fr 4fr;
  gap: 30px;
  text-align: left;
  height: 100%;
}

.degrado-popup>div:not(.degrado-content) .v-button {
  padding: 10px;
  transform: translate(5px, -5px);
}

p.tag {
  background-color: white;
}
</style>