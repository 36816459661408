<template>
  <div class="sheet flex-col" :class="{'orizzontale': type == 'horizontal', 'puntuale': type == 'punctual'}">
    <div class="jc-between">
      <SectionTitle :icon="icon" :title="title" :subtitle="subtitle" :color="color" />
      <VButton :icon="require('@/assets/icons/cross.svg')" @click="close" />
    </div>
    <div class="sheet-box grid gap-20">
      <div class="flex-col sheet-col gap-20">
        <div class="jc-between">
          <div class="gap-10" v-if="type == 'punctual'">
            <p class="tag rounded ai-center gap-5"><strong>DATA </strong>{{ date }}</p>
            <p class="tag rounded ai-center gap-5"><strong>ORA </strong>{{ time }}</p>
          </div>
          <div class="gap-10" v-else>
            <p class="tag rounded ai-center jc-center flex-col"><strong>DATA RIFERIMENTO</strong>{{ date.rif }}</p>
            <p class="tag rounded ai-center jc-center flex-col"><strong>DATA VOLO</strong>{{ date.volo }}</p>
            <p class="tag rounded ai-center jc-center flex-col"><strong>DATA ANALISI</strong>{{ date.analisi }}</p>
          </div>
          <VButton @click="edit" class="medium rounded edit-sheet jc-center" :icon="require('@/assets/icons/edit.svg')" />
        </div>
        <slot name="content"></slot>
        <!-- <SheetGallery :images="monitoring.files" /> -->
      </div>
      <div class="flex-col gap-10">
        <div class="sheet-col flex-col gap-15">
          <slot name="map"></slot>
        </div>
        <slot name="buttons"></slot>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps } from 'vue'
import { useRouter } from 'vue-router';
import SectionTitle from '../SectionTitle.vue';
const props = defineProps(['type', 'icon', 'color', 'title', 'subtitle', 'date', 'time', 'closePath'])

const router = useRouter()

const close = () => {
  // if there is a back path, go back to it
  if (props.closePath && !/(nuovo|\d)$/.test(props.closePath.slice(props.closePath.lastIndexOf("/") + 1))) router.push(props.closePath)
  else if (router.currentRoute.value.query.back) router.push(router.currentRoute.value.query.back)
  else router.push({ path: router.currentRoute.value.meta.onClosePath })
}

const edit = () => {
  const path = router.currentRoute.value.path.split('/')
  path.pop()
  const newPath = path.join('/')
  router.push({ path: newPath })
}
</script>

<style>
.sheet {
  padding: 20px 40px;
  width: 100%;
  height: 100%;
  background-color: var(--base-light-color);
}

.sheet>div:first-child {
  margin-bottom: 20px;
}

.sheet-col {
  background-color: var(--primary-color);
  padding: 20px;
  align-self: stretch;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.sheet-box {
  height: 100%;
  overflow: hidden;
}

.sheet-box div .sheet-col {
  min-height: 55vh;
}

.sheet-box>.sheet-col {
  align-self: self-start;
  max-height: 100%;
  overflow: hidden;
}

.sheet.puntuale .sheet-box {
  grid-template-columns: 7fr 3fr;
}

.sheet.orizzontale .sheet-box {
  grid-template-columns: 3fr 7fr;
  grid-auto-flow: dense;
}

.sheet.orizzontale .sheet-box .sheet-col:first-child {
  grid-column-start: 2;
}

.sheet-box>div {
  text-align: left;
}

.sheet-box strong {
  text-transform: uppercase;
  user-select: none;
}

p.tag {
  display: flex;
  height: 42px;
  background-color: var(--base-light-color);
  padding: 0 15px;
  flex-wrap: wrap;
  font-size: 0.875em;
}

.sheet.orizzontale .sheet-box p.tag {
  width: auto;
  padding: 4px 10px;
}

.v-button.edit-sheet.medium {
  width: 42px;
  background-color: var(--hover-color);
}
</style>