<template>
  <div class="dropzone-container" @dragover="dragover" @dragleave="dragleave" @drop="drop">
    <input type="file" multiple :name="name" id="fileInput" class="hidden-input" @change="onChange" ref="file"
      accept="image/*" />

    <div class="preview-container mt-4" v-if="allTheFiles.length">
      <TransitionGroup name="list" tag="div" appear>
        <div v-for="(file, index) in allTheFiles" :key="file.name" class="preview-card">
          <div>
            <img class="preview-img" :src="fileUrls[index]" />
          </div>
          <VButton class="del-file" :icon="require('@/assets/icons/cross.svg')" @click="remove(file)" :iconSize="10"
            title="Remove file" />
          <VButton class="draw-file" :icon="require('@/assets/icons/pencil.svg')" @click="drawImage[index] = true"
            :iconSize="20" title="Draw image" />
          <Teleport to="#app">
            <DrawImage v-if="drawImage[index]" :image="allTheFiles[index]" :draw="drawings[index]"
              @close="drawImage[index] = false" @save="saveDraw($event, index)" />
          </Teleport>
        </div>
      </TransitionGroup>
    </div>
    <label for="fileInput" class="file-label ai-center">
      <div v-if="isDragging">Rilascia per caricare.</div>
      <Transition name="fade" appear mode="out-in">
        <div v-if="!allTheFiles.length && !isDragging" class="ai-center gap-15 jc-center">Trascina o
          <VButton class="small rounded" label="Clicca qui" title="Upload files" @click="selectFiles" type="button" /> per
          caricare (max. 2 foto).
        </div>
        <VButton v-else-if="allTheFiles.length == 1" class="add-photo rounded jc-center" title="Add photo" @click="selectFiles"
          :icon="require('@/assets/icons/add.svg')" :iconSize="40" />
      </Transition>
    </label>
    <Transition name="shake" appear>
      <b class="error-message" v-if="errorMessage">{{ errorMessage }}</b>
    </Transition>
  </div>
</template>

<script>
import DrawImage from './DrawImage.vue';
import Compressor from 'compressorjs'
import { getDrawingBlob, getPhotoBlob } from '@/utils/utils'

export default {
  props: {
    name: {
      type: String,
      default: "file",
    },
    existingFiles: {
      type: Array,
      default: () => [],
    },
    existingDrawings: {
      type: Array,
      default: () => [],
    },
  },
  emits: ["remove", "change", "saveDraw"],
  data() {
    return {
      isDragging: false,
      files: [],
      fileUrls: [],
      drawImage: [],
      drawings: [],
      errorMessage: "",
    };
  },
  computed: {
    allTheFiles() {
      return [...this.files, ...this.existingFiles];
    },
  },
  watch: {
    existingFiles:  {
      handler: async function() {
        // generate url for each existing file
        const fileUrls = this.existingFiles.map(async (file) => {
          if (file.path) {
            return file.path;
          } else if(typeof file === 'number') {
            let blob = await getPhotoBlob(file)
            return URL.createObjectURL(blob.blob)
          }
          return URL.createObjectURL(file);
        });

        // wait for promises to resolve
        this.fileUrls = await Promise.all(fileUrls);
  
        this.drawings = new Array(this.existingFiles.length).fill(false);
  
        this.drawings.forEach((draw, index) => {
          if (this.existingFiles[index]?.drawing_path) {
            // fetch drawing and put it in drawings array as a blob
            fetch(this.existingFiles[index].drawing_path)
              .then((res) => res.blob())
              .then((blob) => {
                this.drawings[index] = blob;
              });
          } else if(typeof this.existingDrawings[index] === 'number') {
            // fetch drawing and put it in drawings array as a blob
            getDrawingBlob(this.existingDrawings[index])
              .then((blob) => {
                this.drawings[index] = blob.blob;
              });
          }
        })
      }
    },
  },
  methods: {
    selectFiles() {
      this.$refs.file.click();
    },
    async onChange() {
      if (this.allTheFiles.length + this.$refs.file.files.length > 2) {
        this.errorMessage = "Non è possibile caricare più di 2 foto.";
        return;
      }
      this.errorMessage = "";

      const compressionPromises = Array.from(this.$refs.file.files).map((file) =>
        new Promise((resolve, reject) => {
          new Compressor(file, {
            quality: 0.6,
            convertSize: 2000000,  // 2MB
            success: (result) => {
              resolve(result);
            },
            error: (err) => {
              console.log(err.message);
              reject(err);
            },
          });
        })
      );

      Promise.all(compressionPromises)
        .then(async (compressedFiles) => {
          this.files = [...this.files, ...compressedFiles];

          const fileUrlPromises = [...this.files, ...this.existingFiles].map(async (file) => {
            if(typeof file === 'number') {
              let blob = await getPhotoBlob(file)
              return blob.blob
            } else {
              return file
            }
          });

          let files = await Promise.all(fileUrlPromises);
          this.fileUrls = files.map((file) => URL.createObjectURL(file));

          // wait for promises to resolve
          this.$emit("change", files)
        })
        .catch((err) => {
          console.log("An error occurred during compression: ", err);
        });
    },
    dragover(e) {
      e.preventDefault();
      this.isDragging = true;
    },
    dragleave() {
      this.isDragging = false;
    },
    drop(e) {
      e.preventDefault();
      this.$refs.file.files = e.dataTransfer.files;
      this.onChange();
      this.isDragging = false;
    },
    remove(file) {
      // if already uploaded file
      if (file.path) {
        this.$emit("remove", file);
        return;
      } else if(typeof file === 'number') {
        this.$emit("remove", file);
        return;
      }
      this.files.splice(this.files.indexOf(file), 1);
    },
    async generateURL(file) {
      if (typeof file === 'number') {
        file = await getPhotoBlob(file)
      }
      let fileSrc = URL.createObjectURL(file.blob);
      setTimeout(() => {
        URL.revokeObjectURL(fileSrc);
      }, 1000);
      return fileSrc;
    },
    saveDraw(draw, index) {
      console.log(draw, index)
      this.drawings[index] = draw;
      this.drawImage[index] = false;
      this.$emit("saveDraw", this.drawings);
    }
  },
  components: { DrawImage },
};
</script>
<style scoped>
.dropzone-container {
  width: 100%;
  padding: 15px;
  background: #ffffff;
  border: 1px dashed #c6c6c6;
  position: relative;
}

.hidden-input {
  opacity: 0;
  overflow: hidden;
  position: absolute;
  width: 1px;
  height: 1px;
}

.file-label {
  display: flex;
  font-size: 0.875rem;
  cursor: pointer;
  text-transform: uppercase;
  height: 100px;
  width: 100%;
}

.add-photo.v-button {
  height: 90px;
  width: 90px;
  --icon-color: white;
  background-color: var(--base-mid-color);
}

.file-label .v-button {
  background-color: var(--base-mid-color);
}

.file-label>div {
  width: 100%;
}

.preview-container {
  display: flex;
}

.preview-card {
  display: flex;
  padding: 5px;
  margin-left: 5px;
  position: relative;
}

.preview-img {
  object-fit: cover;
  width: 90px;
  height: 90px;
  border-radius: 5px;
  background-color: #a2a2a2;
}

.del-file {
  position: absolute;
  background-color: var(--base-mid-color);
  padding: 6px;
  border-radius: 50%;
  right: -6px;
  top: -9px;
  --icon-color: white;
}

.draw-file {
  position: absolute;
  left: 8px;
  bottom: 8px;
  border-radius: 50%;
  padding: 3px;
  background-color: white;
}

.error-message {
  position: absolute;
  bottom: 5px;
  right: 5px;
  color: var(--alta-color);
}

.v-button {
  color: var(--primary-color)
}
</style>