<template>
    <div class="map-filter flex-col">
        <div class="gap-20 flex-col ai-start">
            <h2>Scegli il tipo di monitoraggio:</h2>
            <DropDown class="outline"
                :options="[{ value: 'horizontal', label: 'Orizzontale' }, { value: 'punctual', label: 'Puntuale' }]"
                @select="selectMonitoringType" :selected="mapStore.selectedMonitoringType" />
            <h4 v-if="mapStore.selectedMonitoringType == 'horizontal'">Strumenti di creazione:</h4>
            <div class="gap-10" v-if="mapStore.selectedMonitoringType == 'horizontal'">
                <VButton class="mid rounded" :iconSize="25" :icon="require('@/assets/icons/quick.svg')" />
                <VButton class="mid rounded" :iconSize="25" :icon="require('@/assets/icons/punctual.svg')" />
            </div>
        </div>
        <div class="gap-20 flex-col ai-start">
            <h2>Filtra per:</h2>
            <DropDown class="solid" :options="MONITORING_TIME_FILTERS" @select="selectDateFilter" :selected="selectedDateFilter" />

            <Transition name="opacity-fast" mode="out-in">
                <div class="orizzontale-filters flex-col gap-20" v-if="mapStore.selectedMonitoringType == 'horizontal'">
                    <div class="intensita flex-col">
                        <h4>Intensità:</h4>
                        <ColorCheckbox v-model="hFilters.intensity[0]" boxColor="var(--alta-color)" label="alta" />
                        <ColorCheckbox v-model="hFilters.intensity[1]" boxColor="var(--media-color)" label="media" />
                        <ColorCheckbox v-model="hFilters.intensity[2]" boxColor="var(--bassa-color)" label="bassa" />
                    </div>
                    <div class="problematica flex-col">
                        <h4>Problematica:</h4>
                        <ColorCheckbox v-model="hFilters.problems[0]" boxColor="white" markColor="black"
                            label="Guaine Danneggiate" />
                        <ColorCheckbox v-model="hFilters.problems[1]" boxColor="white" markColor="black"
                            label="Presenza di Vegetazione" />
                        <ColorCheckbox v-model="hFilters.problems[2]" boxColor="white" markColor="black"
                            label="Caduta Elementi" />
                        <ColorCheckbox v-model="hFilters.problems[3]" boxColor="white" markColor="black"
                            label="Elementi Sconnessi" />
                        <ColorCheckbox v-model="hFilters.problems[4]" boxColor="white" markColor="black"
                            label="Accumulo Acqua" />
                    </div>
                </div>

                <div class="puntuale-filters flex-col gap-15" v-else>
                    <!-- <h4>Tipologia referente topografico:</h4> -->
                    <div class="flex-col gap-5">
                        <ColorCheckbox boxColor="white" markColor="black" label="Per unità" />
                        <ColorCheckbox boxColor="white" markColor="black" label="Per Ambienti" />
                        <ColorCheckbox boxColor="white" markColor="black" label="Per Tip. Ref.Top." />
                    </div>
                    <h4>Indice:</h4>
                    <div class="flex-col gap-5">
                        <RadioButton v-model="mapStore.punctualMonitoring.index" value="ia" label="IA" />
                        <div v-if="mapStore.punctualMonitoring.index === 'ia'" class="flex-col ml-25">
                            <ColorCheckbox v-model="pFilters.ia[0]" boxColor="var(--alta-color)" label="alta" />
                            <ColorCheckbox v-model="pFilters.ia[1]" boxColor="var(--media-alta-color)"
                                label="media-alta" />
                            <ColorCheckbox v-model="pFilters.ia[2]" boxColor="var(--media-color)" label="media" />
                            <ColorCheckbox v-model="pFilters.ia[3]" boxColor="var(--media-bassa-color)"
                                label="media-bassa" />
                            <ColorCheckbox v-model="pFilters.ia[4]" boxColor="var(--bassa-color)" label="bassa" />
                        </div>
                    </div>
                    <div class="flex-col gap-5">
                        <RadioButton v-model="mapStore.punctualMonitoring.index" value="ifr" label="IFR" />
                        <div v-if="mapStore.punctualMonitoring.index === 'ifr'" class="flex-col ml-25">
                            <ColorCheckbox v-model="pFilters.ifr[0]" boxColor="var(--alta-color)" label="alta" />
                            <ColorCheckbox v-model="pFilters.ifr[1]" boxColor="var(--media-alta-color)"
                                label="media-alta" />
                            <ColorCheckbox v-model="pFilters.ifr[2]" boxColor="var(--media-color)" label="media" />
                            <ColorCheckbox v-model="pFilters.ifr[3]" boxColor="var(--media-bassa-color)"
                                label="media-bassa" />
                            <ColorCheckbox v-model="pFilters.ifr[4]" boxColor="var(--bassa-color)" label="bassa" />
                        </div>
                    </div>

                    <!-- Filter by tip. ref.top. NOT implemented yet -->
                    <!-- <RadioButton v-model="pFilters.type" value="topref" label="Per Tip. Ref.Top." /> -->
                </div>
            </Transition>
            <div class="top-ref-label flex-col gap-15">
                <p>Hai selezionato:</p>
                <div class="flex-col gap-5">
                    <p>Regione: {{ findNestedKey(mapStore.currentTopographicReference, 'region')?.name || mapStore.currentTopographicReference?.name || '-' }}</p>
                    <p>Insula: {{ findNestedKey(mapStore.currentTopographicReference, 'insula')?.name ||
                            (mapStore.currentTopographicReference?.region ? mapStore.currentTopographicReference?.name : '-') }}</p>
                    <p>Unità: {{ findNestedKey(mapStore.currentTopographicReference, 'unity')?.name ||
                            (mapStore.currentTopographicReference?.insula ? mapStore.currentTopographicReference?.name : '-') }}</p>
                    <p>Ambiente: {{ findNestedKey(mapStore.currentTopographicReference, 'environment')?.name ||
                            (mapStore.currentTopographicReference?.unity ? mapStore.currentTopographicReference.name : '-') }}</p>
                    <!-- <p>Referente topografico: {{ (mapStore.currentTopographicReference?.environment ? mapStore.currentTopographicReference?.name : '-') }}</p> -->
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, reactive, defineProps, watch } from 'vue'
import ColorCheckbox from '../ColorCheckbox.vue';
import RadioButton from '../RadioButton.vue';
import DropDown from '../DropDown.vue';

import { useMapStore } from '@/stores/map';
import { findNestedKey } from '@/utils/utils';
import { MONITORING_TIME_FILTERS } from '@/utils/constants';

const mapStore = useMapStore()

defineProps({ structure: { type: Object, default: () => { } } })

const selectedDateFilter = ref(null)

const hFilters = reactive({
    intensity: [false, false, false],
    problems: [false, false, false, false, false],
})

const pFilters = reactive({
    type: 'unit',
    subtype: 'ia',
    ia: [false, false, false, false, false],
    ifr: [false, false, false, false, false],
})

const selectMonitoringType = (type) => {
    mapStore.previousSelectedMonitoringType = mapStore.selectedMonitoringType;
    mapStore.selectedMonitoringType = type.value;
}
const selectDateFilter = (type) => {
    selectedDateFilter.value = type.value;
}

/*const clearFilters = () => {
    pFilters.ia = [false, false, false, false, false]
    pFilters.ifr = [false, false, false, false, false]
}*/


watch(
    hFilters,
    () => {
        console.log('Horizontal filters CHANGED');
    }
)
watch(
    () => mapStore.punctualMonitoring.topographicLevel,
    () => {
        console.log('TYPE CHANGED');
    },
)
watch(
    () => mapStore.punctualMonitoring.index,
    () => {
        console.log('SUBTYPE CHANGED');
    },
)
</script>

<style>
.map-filter {
    width: 100%;
    height: 100%;
    background-color: #292426;
    padding: 40px 25px;
    user-select: none;
    overflow-y: auto;
    gap: 50px;
}

.map-filter > div:last-child {
    height: 100%;
}

.map-filter h3,
.map-filter h4,
.map-filter h2 {
    text-align: left;
    color: white;
}

.map-filter h2 {
    font-weight: 300;
}

.map-filter h4 {
    font-weight: 400;
}

.map-filter .color-checkbox {
    color: white;
    padding: 5px 0;
    height: 28px;
}

.map-filter .orizzontale-filters .problematica .color-checkbox {
    text-transform: unset;
    font-weight: 400;
}

.map-filter .puntuale-filters div:first-child { margin-top: 5px; }
.map-filter .puntuale-filters h4 { margin-top: 10px; }

.map-filter .color-checkbox .checkbox {
    height: 13px;
}

.map-filter .radiobutton {
    height: 17px;
    color: white;
    text-transform: uppercase;
    font-size: 0.875em;
    font-weight: 700;
}

.map-filter .acc-content {
    margin-left: 40px;
    display: none;
    opacity: 0;
}

.map-filter .chckbox-group {
    margin-left: 25px;
}

.map-filter .title {
    height: 30px;
}

.puntuale-radio {
    text-align: left;
    font-size: 13px;
    font-weight: 700;
    color: white;
    text-transform: uppercase;
    display: flex;
}

.puntuale-radio input {
    width: 18px;
    height: 18px;
}

.acc-content.active {
    display: flex;
    opacity: 1;
    margin-bottom: 15px;
}

.map-filter .v-lottie.arrow svg path {
    stroke: white;
}

.map-filter .mid.rounded {
    padding: 8px;
    --icon-color: white;
}

.map-filter .dropdown.outline .selected {
    background: unset;
    border: 1px solid white
}

.map-filter .dropdown.outline .category .box {
    border: 1px solid white;
    background-color: var(--base-dark-color);
}

.map-filter .dropdown.solid :is(.selected, .category .box) {
    background-color: #3E393B;
}

.ml-25 {
    margin-left: 25px;
}

.top-ref-label > p {
    font-weight: 700;
}
.top-ref-label {
    margin-top: auto;
    padding: 20px 15px;
    text-align: left;
    width: 100%;
    font-size: 12px;
    line-height: 12.5px;
    letter-spacing: 0.03rem;
    font-weight: 500;
    color: white;
    text-transform: uppercase;
    background-color: #3E393B;
}
</style>