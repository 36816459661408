<template>
  <div class="section-title">
    <VIcon v-if="icon" class="ai-center jc-center" :class="{border: border}" :src="require('@/assets/icons/'+icon+'.svg')" :style="{backgroundColor: color}" />
    <div>
      <h2 v-if="title">{{title}}</h2>
      <b v-if="subtitle">{{subtitle}}</b>
    </div>
  </div>
</template>
<script>
export default {
  props: ['title', 'subtitle', 'icon', 'color', 'border']
}
</script>
<style scoped>
.section-title {
  align-items: center;
  gap: 1em;
  user-select: none;
}

.section-title .v-icon {
  border-radius: 30px;
  height: 44px;
  width: 44px;
}

.section-title > div > h2 {
  margin-bottom: 4px;
}

.v-icon.border {
  border: 1px solid var(--icon-color);
}

.section-title > div:last-child {
  flex-direction: column;
  align-items: flex-start;
}
</style>