<template>
  <ShowMonitoraggioTemplate v-if="monitoring" type="horizontal" :color="'#' + monitoring.color" icon="horizontal"
    :title="monitoring.horizontal_degradation_type.name + ' - monitoraggio #' + monitoring.id" :subtitle="monitoring.drone_flight.name"
    :date="{ rif: monitoring.date, volo: monitoring.drone_flight.date, analisi: monitoring.drone_flight.analysis_date }" :closePath="router.options.history.state.back">
    <template #content>
      <div class="gap-5 flex-col">
        <p><strong>Tipo di monitoraggio: </strong>Orizzontale</p>
        <p><strong>PROGETTO DI RIFERIMENTO: </strong>{{ monitoring.project.name }}</p>
        <p><strong>PROBLEMATICA: </strong>{{ monitoring.horizontal_degradation_type.name }}</p>
        <p><strong>INTENSITÀ: </strong>{{ monitoring.horizontal_degradation_intensity.name }}</p>
        <p><strong>SEGNALATORE: </strong>{{ monitoring.user.full_name }}</p>
        <p><strong>OSSERVAZIONI: </strong>{{ monitoring.observations || '-' }}</p>
      </div>
    </template>

    <template #map>
      <VMap :polygons="monitoring ? [polygon] : []" :customLayers="MAP_LAYERS" :currentLayer="1" />
    </template>

    <!-- <template #buttons>
      <VButton class="medium rounded action mid" label="Risolvi" />
    </template> -->
  </ShowMonitoraggioTemplate>
</template>
<script setup>
import getMonitoring from '@/services/composables/getMonitoring';
import { useRouter } from 'vue-router';
import { computed, onMounted } from 'vue';
import ShowMonitoraggioTemplate from './ShowMonitoraggioTemplate.vue';
import { MAP_LAYERS, POLYGON_STYLES } from '@/utils/polygon';

const router = useRouter();

const { monitoring, load } = getMonitoring('horizontal-monitoring', router.currentRoute.value.params.id);

const polygon = computed(() => {
  console.log(monitoring)
  if (monitoring.value && monitoring.value.polygon) {
    let styledPolygon = monitoring.value
  
    styledPolygon.style = {
      fillColor: "#" + monitoring.value.color,
      ...POLYGON_STYLES.circleMarker
    }
    
    return styledPolygon
  }

  return null
});

onMounted(() => {
  load().then(() => {
  });

  console.log('the router', router.options.history.state.back);
});

</script>