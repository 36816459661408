<template>
  <div class="modal-cont ai-center jc-center">
    <div class="modal flex-col gap-10">
      <VButton @click="$emit('close')" class="close" :icon="require('@/assets/icons/cross.svg')" />
      <p>Aggiungi nuovo</p>
      <!--<VButton @click="createNew('quickMonitoringForm')" class="medium rounded gap-10"
        :icon="require('@/assets/icons/quick.svg')" label="Monitoraggio Speditivo" />-->
      <VButton @click="(createNew('horizontalMonitoringForm'))" class="medium rounded gap-10"
        :icon="require('@/assets/icons/horizontal.svg')" label="Monitoraggio Orizzontale" />
      <VButton @click="createNew('punctualMonitoringForm')" class="medium rounded gap-10"
        :icon="require('@/assets/icons/punctual.svg')" label="Monitoraggio Puntuale" />
    </div>
  </div>
</template>
<script setup>
import { defineEmits, onUnmounted } from 'vue'
import { useUXStore } from '@/stores/ux';
const emit = defineEmits(['close', 'select'])
const uxstore = useUXStore()

const createNew = (type) => {
  emit('select', type)
}

onUnmounted(() => {
  uxstore.showOverlay = false
})
</script>
<style>
.modal-cont {
  width: 100%;
  height: 100%;
  position: absolute;
}

.modal {
  width: 230px;
  padding: 30px;
  position: relative;
  background-color: white;
  z-index: 5;
}

.modal p {
  margin-top: 15px;
}

.modal .v-button:not(.close) {
  background-color: var(--base-dark-color);
  padding: 0 10px;
  --icon-color: var(--primary-color);
}

.modal .v-button .label {
  font-size: 0.75rem;
  letter-spacing: normal;
  text-align: left;
  color: var(--primary-color);
}

.modal .v-button.close {
  position: absolute;
  right: 15px;
  top: 15px;
}
</style>