<template>
  <label class="radiobutton ai-center gap-10" @mouseenter="onHover()" @mouseleave="onUnhover()" @click="onSelect()">
    <VLottie ref="radio" :src="require('@/assets/icons/radiobutton.json')" />
    {{ label }}
  </label>
</template>

<script setup>
import { defineProps, ref, defineEmits, watch, onMounted } from 'vue'

const props = defineProps(['modelValue', 'label', 'value'])
const emit = defineEmits(['update:modelValue'])

const radio = ref(null)

function onHover() { if (props.modelValue !== props.value) radio?.value.playSegments([0, 10], true) }
function onUnhover() { if (props.modelValue !== props.value) radio?.value.playSegments([10, 20], true) }
function onSelect() { emit('update:modelValue', props.value) }

watch(() => props.modelValue, (newValue, oldValue) => {
  if (props.value === newValue) radio?.value?.playSegments([20, 45], true)
  else if (props.value === oldValue) radio?.value?.playSegments([49, 59], true)
})

onMounted(() => {
  if (props.modelValue == props.value) radio?.value?.playSegments([44, 45], true)
})
</script>

<style>
.radiobutton {
  display: flex;
  height: 100%;
}

.radiobutton .v-lottie {
  height: 100%;
}
</style>