<template>
  <div class="rotate-device ai-center jc-center">
    <p>Gira il dispositivo per usufruire di un'esperienza ottimale</p>
  </div>
</template>
<script>
export default {
  
}
</script>
<style scoped>
  .rotate-device {
    position: fixed;
    display: flex;
    height: 100vh;
    width: 100vw;
    z-index: 9999;
    background-color: white;
    font-size: 20px;
  }
</style>