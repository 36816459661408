<template>
  <Transition name="opacity" appear>
    <img id="login-image" src="@/assets/login.jpg">
  </Transition>
  <Transition name="login" appear>
    <div class="login-container">
      <VIcon :src="require('@/assets/pompei_logo.svg')" :size="209" />
      <form @submit.prevent="handleSubmit">
        <p class="login-error" v-if="auth.error">{{ auth.error }}</p>
        <VInput class="medium bg-primary-color" type="email" placeholder="Email" :required="true" autocomplete="on"
          v-model="cred.email" />
        <div class="flex-col">
          <VInput class="medium bg-primary-color" type="password" placeholder="Password" :required="true"
            v-model="cred.password" />
          <Transition name="overlay" mode="out-in">
            <VButton @click="toggleRecover" v-if="!showRecover" class="recover-password" label="Password dimenticata?" />
            <p class="recover" style="user-select: none;" v-else>Contacta con <a href="mailto:webmaster@example.com"
                style="user-select: all;">info@pompeii.com</a> para recuperar tu contraseña</p>
          </Transition>
        </div>
        <VButton v-if="!auth.loading" class="accent medium rounded" label="Entra" type="submit" />
        <VLottie v-else class="login-loading rounded" :src="require('@/assets/icons/loading.json')" autoplay loop />
      </form>
    </div>
  </Transition>
</template>
<script setup>
import { ref, reactive } from 'vue'
import { useAuthStore } from '@/stores/auth';

const auth = useAuthStore();
const cred = reactive({
  email: '',
  password: ''
})

const showRecover = ref(false)
const toggleRecover = () => {
  showRecover.value = true
  setTimeout(() => {
    showRecover.value = false
  }, 5000);

}

const handleSubmit = async () => {
  await auth.login(cred.email, cred.password)
}
</script>

<style>
#login-image {
  height: 100%;
  width: 100%;
  object-fit: cover;
  position: fixed;
}

.login-container {
  height: 30%;
  width: 100%;
  position: absolute;
  bottom: 0;
  background: var(--base-dark-color);
  align-items: center;
  justify-content: center;
  box-shadow: 0px -3px 12px 2px #12121294;
  gap: 80px;
}

.login-container form {
  position: relative;
  align-items: flex-start;
  gap: 15px;
  margin-top: 45px;
}


.login-container .v-input {
  width: 250px;
}

.login-container .v-input:focus-within {
  border-bottom: 3px solid var(--accent-color);
}

.login-container .v-button .label {
  color: white;
  font-weight: 700;
}

.login-container .v-button[type="submit"] {
  padding: 0 20px;
  letter-spacing: 0.1rem;
  transition: all .1s ease;
  font-weight: 700;
}

.login-container .v-button[type="submit"]:hover {
  background-color: #dc7813;
}

.v-button.recover-password .label {
  padding: 0;
  font-size: min(12px, 0.95vw);
  letter-spacing: 0.22rem;
  transition: all .2s ease;
}

.v-button.recover-password {
  margin: 16px 5px;
}

.v-button.recover-password:hover .label {
  color: var(--accent-color);
}

.recover {
  width: 250px;
  height: 26px;
  color: white;
  text-align: left;
  margin: 10px 0;
  line-height: 20px;
  font-size: 15px;
  font-weight: 300;
  letter-spacing: 0.05em;
}

.recover a {
  color: white;
}

.login-loading {
  height: 42px;
    width: 92px;
    background: #e8831c73;
}
.login-error {
  position: absolute;
  top: -25px;
  font-size: 0.875rem;
  color: var(--alta-color);
  letter-spacing: 0.01rem;
}
</style>