import { defineStore } from 'pinia'
import router from '@/router'
import { axiosInstanceWithAuth } from '@/utils/utils'
import { useUXStore } from './ux'

export const useAuthStore = defineStore({
    id: 'auth',
    state: () => ({
        authenticated: null,
        user: {
            id: null,
            name: null,
        },
        error: null,
        loading: false,
    }),
    getters: {
        isAuthenticated() {
            return this.authenticated
        },
    },
    actions: {
        login(email, password) {
            this.error = null
            this.loading = true
            axiosInstanceWithAuth.get('sanctum/csrf-cookie').then(() => {
                axiosInstanceWithAuth.post('/api/login', {
                    email: email,
                    password: password
                }).then(async (res) => {
                    console.log('LOGIN');
                    this.authenticated = true
                    this.user = res.data.user
                    await router.push({ name: 'manutenzione' })
                    useUXStore().mountAllMenu()
                    useUXStore().showMainMenu()
                }).catch((error) => {
                    if (error?.response.status == 401) this.error = 'Email o password errati'
                    else this.error = error.response.data.message
                }).finally(() => {
                    this.loading = false
                })
            })
        },
        async logout() {
            if (this.authenticated) {
                axiosInstanceWithAuth.post('/api/logout').then(() => {
                    this.authenticated = false
                    router.push('/login')
                })
            }
        },
    }
})
