import axios from "axios";
import { useAuthStore } from "@/stores/auth";
import { openDB } from "idb";
import { punctualMonitoringDBParams } from "../services/composables/useMonitoring";

let url = process.env.VUE_APP_API_URL;

export const axiosInstanceWithAuth = axios.create({
    baseURL: url,
    withCredentials: true,
    headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
        'X-Requested-With': 'XMLHttpRequest',
    }
});
axiosInstanceWithAuth.defaults.timeout = 200000;

// handle 401 errors
axiosInstanceWithAuth.interceptors.response.use(
    response => response,
    error => {
        if (error.response.status === 401 || error.response.status === 419) {
            useAuthStore().logout();
        }

        return Promise.reject(error);
    }
);

export async function getPhotoBlob(id) {
    const db = await openDB('punctual-monitoring-page-local-sync', punctualMonitoringDBParams.version, punctualMonitoringDBParams)
    const blob = await db.get("photoBlobs", id)
    db.close()
    return blob
}

export async function getDrawingBlob(id) {
    const db = await openDB('punctual-monitoring-page-local-sync', punctualMonitoringDBParams.version, punctualMonitoringDBParams)
    const blob = await db.get("drawingBlobs", id)
    db.close()
    return blob
}

export async function readFileAsBlob(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsArrayBuffer(file);
        reader.onload = () => {
            const blob = new Blob([new Uint8Array(reader.result)], { type: file.type });
            resolve(blob);
        };
        reader.onerror = (error) => reject(error);
    });
}

export function blobToFile(blob, fileName) {
    return new File([blob], fileName, { type: blob.type, lastModified: Date.now() });
}

export function blobToBase64(blob) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });
}

export function blobToImageData(blob) {
    return new Promise((resolve, reject) => {
        const url = URL.createObjectURL(blob);

        const img = new Image();
        img.src = url;
        img.onload = () => {

            const canvas = document.createElement('canvas');
            canvas.width = img.width;
            canvas.height = img.height;

            const ctx = canvas.getContext('2d');
            ctx.drawImage(img, 0, 0);

            const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);

            URL.revokeObjectURL(url);

            resolve(imageData);
        };

        img.onerror = (err) => {
            URL.revokeObjectURL(url);
            reject(err);
        };
    });
}

export function findNestedKey(obj, targetKey) {
    // Caso base: l'oggetto è null o non è un oggetto/array
    if (obj === null || (typeof obj !== 'object' && !Array.isArray(obj))) {
        return null;
    }

    // Controllo se l'oggetto ha la chiave target
    if (Object.prototype.hasOwnProperty.call(obj, targetKey)) {
        return obj[targetKey];
    }

    // Ricerca ricorsiva nei sotto-oggetti e negli array
    for (let key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key) || Array.isArray(obj)) {
            const result = findNestedKey(obj[key], targetKey);
            if (result !== null) {
                return result;
            }
        }
    }

    return null;
}
