<template>
  <div class="draw ai-center jc-center">
    <VButton class="close" @click="emit('close')" :icon="require('@/assets/icons/cross.svg')" />
    <VLottie v-show="loading" :src="require('@/assets/icons/loading.json')" autoplay loop />
    <div v-show="!loading" class="canvas-cont" ref="canvasCont">
      <canvas class="img-canvas" ref="imgCanvas" />
      <canvas class="draw-canvas" ref="drawCanvas" @touchstart="startDraw" @touchmove="doDraw" @touchend="stopDraw"
        @mousedown="startDraw" @mousemove="doDraw" @mouseup="stopDraw" />
    </div>
    <div class="draw-tools ai-center jc-between">
      <div class="gap-10">
        <VButton v-if="drawingModified || !lastDraw" class="tool" :class="{ disabled: paths.length == 0 }"
          @click="undoPath" :icon="require('@/assets/icons/undo.svg')" :iconSize="30" />
        <VButton v-if="drawingModified || !lastDraw" class="tool" :class="{ disabled: pathsHistory.length == 0 }"
          @click="redoPath" :icon="require('@/assets/icons/redo.svg')" :iconSize="30" />
        <VButton v-if="!drawingModified && lastDraw" class="tool del" @click="clearCanvas"
          :icon="require('@/assets/icons/trash.svg')" :iconSize="28" />
      </div>
      <div class="colors gap-10">
        <input type="radio" :style="{ '--radio-color': colors[0] }" v-model="color" value="0" id="bassa">
        <input type="radio" :style="{ '--radio-color': colors[1] }" v-model="color" value="1" id="media-bassa">
        <input type="radio" :style="{ '--radio-color': colors[2] }" v-model="color" value="2" id="media">
        <input type="radio" :style="{ '--radio-color': colors[3] }" v-model="color" value="3" id="media-alta">
        <input type="radio" :style="{ '--radio-color': colors[4] }" v-model="color" value="4" id="alta">
      </div>
      <div class="gap-5">
        <VIcon :src="require('@/assets/icons/circle.svg')" :size="10" />
        <input type="range" v-model="strokeWidth" min="2" max="12">
        <VIcon :src="require('@/assets/icons/circle.svg')" :size="20" />
      </div>
      <div class="buttons">
        <Transition name="fade">
          <VButton v-if="drawingModified" class="rounded" name="save" @click="saveDraw" label="SALVA" />
        </Transition>
      </div>
    </div>
  </div>
</template>
<script setup>
import { axiosInstanceWithAuth, blobToImageData } from '@/utils/utils'
import { ref, defineProps, defineEmits, onMounted } from 'vue'
import { openDB } from 'idb';
import { punctualMonitoringDBParams } from '@/services/composables/useMonitoring';

const props = defineProps({ image: { required: true }, draw: { default: null } })
const emit = defineEmits(['close', 'save'])
const imgCanvas = ref(null);
const drawCanvas = ref(null);
const canvasCont = ref(null);
const lastDraw = ref(props.draw)
let ctx = null, imgCtx = null;
let imgWidth, imgHeight
const maxWidth = screen.width * 0.65
const maxHeight = screen.height * 0.65
const loading = ref(false)
const color = ref(0)
const strokeWidth = ref(7)
const colors = ['#519acf', '#33ad73', '#ffd25e', '#fb8e50', '#f2585c']
const isDrawing = ref(false)
const paths = ref(lastDraw.value ? [lastDraw.value] : [])
const pathsHistory = ref([])

// variables to check if the drawing has been modified and needs to be saved
const drawingModified = ref(false)
const possibleToResetFirstDrawing = ref(true)


const getScaledDim = (img) => {
  var width = img.width;
  var height = img.height;

  if (width > maxWidth || height > maxHeight) {
    var aspectRatio = width / height;
    if (aspectRatio > maxWidth / maxHeight) {
      width = maxWidth;
      height = width / aspectRatio;
    } else {
      height = maxHeight;
      width = height * aspectRatio;
    }
  }
  return { width: width, height: height };
}

function loadImage(src) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.crossOrigin = "Anonymous";
    img.onload = () => resolve(img);
    img.onerror = reject;
    img.src = src;
  });
}

async function fetchImageData(url) {
  const response = await axiosInstanceWithAuth.get(url, { responseType: 'blob' });
  return new Blob([response.data]);
}

async function readAsDataURL(blob) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
}

async function loadCanvas() {
  try {
    loading.value = true;

    imgCtx = imgCanvas.value.getContext('2d');
    ctx = drawCanvas.value.getContext('2d', { willReadFrequently: true });

    let imageData;
    if (props.image.path) {
      imageData = await fetchImageData(props.image.path);
    } else if(typeof props.image === 'number'){
      // take image data from drawingBlobs db
      let db = await openDB('punctual-monitoring-page-local-sync', punctualMonitoringDBParams.version, punctualMonitoringDBParams)
      imageData = await db.get('photoBlobs', props.image)
      imageData = imageData.blob
    } else {
      imageData = props.image;
    }

    const dataURL = await readAsDataURL(imageData);
    const img = await loadImage(dataURL);

    const scaled = getScaledDim(img);
    imgHeight = scaled.height;
    imgWidth = scaled.width;

    drawCanvas.value.width = imgWidth;
    drawCanvas.value.height = imgHeight;
    imgCtx.canvas.width = imgWidth;
    imgCtx.canvas.height = imgHeight;

    canvasCont.value.style.width = imgWidth + 'px';
    canvasCont.value.style.height = imgHeight + 'px';

    imgCtx.drawImage(img, 0, 0, imgCtx.canvas.width, imgCtx.canvas.height);

    if (lastDraw.value) {
      let prevDraw = null;

      if (lastDraw.value instanceof Blob) {
        const dataURL = await readAsDataURL(lastDraw.value);
        prevDraw = await loadImage(dataURL);
      } else {
        prevDraw = await loadImage(lastDraw.value);
      }

      ctx.drawImage(prevDraw, 0, 0, ctx.canvas.width, ctx.canvas.height);
    }

  } catch (error) {
    console.error("Error loading canvas:", error);
  } finally {
    loading.value = false;
  }
}

function startDraw(e) {
  // ctx = drawCanvas.value.getContext('2d')
  let o = drawCanvas.value.getBoundingClientRect();
  isDrawing.value = true;
  ctx.beginPath();
  ctx.moveTo(e.clientX - o.left, e.clientY - o.top);
  e.preventDefault();
  drawingModified.value = true;
}

function doDraw(e) {
  if (isDrawing.value) {
    let x = 0;
    let y = 0;
    if (e.type == 'touchmove') {
      let evt = (typeof e.originalEvent === 'undefined') ? e : e.originalEvent;
      let touch = evt.touches[0] || evt.changedTouches[0];
      x = touch.clientX;
      y = touch.clientY;
    } else if (e.type == 'mousemove') {
      x = e.clientX;
      y = e.clientY;
    }
    let o = drawCanvas.value.getBoundingClientRect();
    ctx.lineTo(x - o.left, y - o.top);
    ctx.strokeStyle = colors[color.value];
    ctx.lineWidth = strokeWidth.value;
    ctx.lineCap = 'round';
    ctx.lineJoin = 'round';
    ctx.stroke();
  }
  e.preventDefault();
}

function stopDraw(e) {
  if (isDrawing.value) {
    ctx.stroke();
    ctx.closePath();
    isDrawing.value = false;
  }
  e.preventDefault();

  paths.value.push(ctx.getImageData(0, 0, drawCanvas.value.width, drawCanvas.value.height));

}

function clearCanvas() {
  ctx.clearRect(0, 0, drawCanvas.value.width, drawCanvas.value.height);
  paths.value = [];
  //pathsHistory.value = [];

  lastDraw.value = null

  drawingModified.value = true
  possibleToResetFirstDrawing.value = true
}

async function undoPath() {
  if (paths.value.length == 0) return;

  pathsHistory.value.push(paths.value.pop());

  if (paths.value.length == 0) {
    clearCanvas();
  } else {
    // the previous drawing is drawn
    if (paths.value[paths.value.length - 1] instanceof Blob) {
      paths.value[paths.value.length - 1] = await blobToImageData(paths.value[paths.value.length - 1])
    }

    ctx.putImageData(paths.value[paths.value.length - 1], 0, 0);
  }

  if (possibleToResetFirstDrawing.value && paths.value.length == 1) {
    drawingModified.value = false
  }
}

function redoPath() {
  if (pathsHistory.value.length == 0) return;

  paths.value.push(pathsHistory.value.pop());
  ctx.putImageData(paths.value[paths.value.length - 1], 0, 0);

  drawingModified.value = true
}

function saveDraw() {
  // If there are new/any drawings save them, otherwise just close
  if (paths.value.length) {
    drawCanvas.value.toBlob((blob) => {
      emit('save', blob)
    }, 'image/png')
  } else {
    emit('save', null)
  }
}

onMounted(() => {
  loadCanvas()
})
</script>
<style scoped>
.draw {
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.38);
  z-index: 5;
}

.draw .draw-canvas {
  z-index: 10;
}

.draw .v-button.close {
  background: white;
  box-shadow: 0px 4px 4px #00000090;
  border-radius: 50%;
  padding: 10px;
  position: absolute;
  top: 30px;
  right: 30px;
}

.draw .v-button.tool {
  opacity: 0.8;
}

.draw .v-button.tool.del {
  margin: 0px 21px;
}

.draw .v-button.tool.disabled {
  opacity: 0.4;
}

.draw .img-canvas {
  box-shadow: 0px 3px 11px #00000090;
}

.draw .canvas-cont canvas {
  position: absolute;
}

.draw .canvas-cont {
  position: relative;
}

.draw .draw-tools {
  height: 60px;
  width: 580px;
  background-color: white;
  position: absolute;
  bottom: 0;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.306);
  padding: 5px 15px;
}


/* STYLE INPUT RADIO */
input[type='radio'] {
  height: 25px;
  width: 25px;
  cursor: pointer;
}

input[type='radio']:after {
  width: 25px;
  height: 25px;
  border-radius: 25px;
  top: -2px;
  left: -1px;
  position: relative;
  background-color: var(--radio-color);
  content: '';
  display: inline-block;
  visibility: visible;
  border: 2px solid white;
}

input[type='radio']:checked:after {
  width: 25px;
  height: 25px;
  border-radius: 25px;
  top: -2px;
  left: -1px;
  position: relative;
  outline: 1px solid #484848;
  outline-width: 2px;
  outline-offset: 1px;
  content: '';
  display: inline-block;
  visibility: visible;
  border: 2px solid white;
}


/* STYLE INPUT RANGE */
input[type=range] {
  height: 28px;
  -webkit-appearance: none;
  margin: 10px 0;
  width: 100px;
}

input[type=range]:focus {
  outline: none;
}

input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 2px;
  cursor: pointer;
  animate: 0.2s;
  box-shadow: 0px 0px 0px #000000;
  background: #B5B5B5;
  border-radius: 4px;
  border: 0px solid #000000;
}

input[type=range]::-webkit-slider-thumb {
  box-shadow: 1px 1px 6px #B8B8B8;
  border: 1px solid #212121;
  height: 16px;
  width: 16px;
  border-radius: 20px;
  background: #FFFFFF;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -7.5px;
}

input[type=range]:focus::-webkit-slider-runnable-track {
  background: #B5B5B5;
}

input[type=range]::-moz-range-track {
  width: 100%;
  height: 2px;
  cursor: pointer;
  animate: 0.2s;
  box-shadow: 0px 0px 0px #000000;
  background: #B5B5B5;
  border-radius: 4px;
  border: 0px solid #000000;
}

input[type=range]::-moz-range-thumb {
  box-shadow: 1px 1px 6px #B8B8B8;
  border: 1px solid #212121;
  height: 16px;
  width: 16px;
  border-radius: 20px;
  background: #FFFFFF;
  cursor: pointer;
}

input[type=range]::-ms-track {
  width: 100%;
  height: 2px;
  cursor: pointer;
  animate: 0.2s;
  background: transparent;
  border-color: transparent;
  color: transparent;
}

input[type=range]::-ms-fill-lower {
  background: #B5B5B5;
  border: 0px solid #000000;
  border-radius: 8px;
  box-shadow: 0px 0px 0px #000000;
}

input[type=range]::-ms-fill-upper {
  background: #B5B5B5;
  border: 0px solid #000000;
  border-radius: 8px;
  box-shadow: 0px 0px 0px #000000;
}

input[type=range]::-ms-thumb {
  margin-top: 1px;
  box-shadow: 1px 1px 6px #B8B8B8;
  border: 1px solid #212121;
  height: 16px;
  width: 16px;
  border-radius: 20px;
  background: #FFFFFF;
  cursor: pointer;
}

input[type=range]:focus::-ms-fill-lower {
  background: #B5B5B5;
}

input[type=range]:focus::-ms-fill-upper {
  background: #B5B5B5;
}
</style>