<template>
  <div class="status ai-center jc-between" :style="{ backgroundColor: '#' + (color ? color : selectedOption.color) }"
    :class="{ disabled: !isField }" @click="updateValue">
    <input type="hidden" :name="name" :value="selectedOption.value" v-if="isField" />
    <p>{{ label ? label : selectedOption.label }}</p>
    <VIcon :src="require('@/assets/icons/status-label.svg')" v-if="isField" />
  </div>
</template>
<script>
import { ref } from 'vue';

export default {
  props: {
    color: { type: String, default: '' },
    label: { type: String, default: '' },
    options: {
      type: Array,
      required: false,
      default: () => [{
        text: "Priorità",
        value: 0,
        color: "#F2F2F2"
      }]
    },
    name: {
      type: String,
      required: false,
      default: ""
    },
    modelValue: {
      type: Number,
      required: false,
      default: 0
    }
  },
  computed: {
    selectedOption() {
      if (this.name !== '' && this.modelValue) {
        return this.options.find(option => option.value === this.modelValue)
      } else {
        return this.options[this.index]
      }
    },
    isField() {
      return this.name !== '' && this.modelValue
    }
  },
  methods: {
    updateValue() {
      this.index = (this.index + 1) % this.options.length
      this.$emit('update:modelValue', this.options[this.index].value)
    }
  },
  setup(props) {
    const index = ref(0)

    if (props.modelValue) {
      index.value = props.options.findIndex(option => option.value === props.modelValue)
    }

    return {
      index
    }
  }
}
</script>
<style scoped>
.status {
  height: 42px;
  width: 100%;
  padding: 0 15px;
  cursor: pointer;
  user-select: none;
  transition: all .4s ease;
}

.status.disabled {
  cursor: unset;
}

.status:hover .v-icon {
  opacity: 1;
}

.status .v-icon {
  width: 10px;
  opacity: 0.6;
}

.status:active .v-icon {
  transform: scale(0.9);
}

p {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 0.8125rem;
}
</style>