<template>
  <div class="ref-top ai-center jc-center">
    <div class="popup flex-col gap-20">
      <div class="jc-between">
        <div class="ai-center gap-10">
          <VIcon :src="require('@/assets/icons/ref-top.svg')" />
          <span class="title">Referente topografico</span>
        </div>
        <VButton class="close" :icon="require('@/assets/icons/cross.svg')" @click="close" />
      </div>
      <div class="grid grid-2-col gap-30">
        <div class="grid grid-2-col gap-5" v-if="!loading">
          <VInput class="grid-col-span medium solid" :icon="require('@/assets/icons/search.svg')" :iconSize="15"
            type="text" v-model="searchQuery" />
          <DropDown :options="regionsDropdown" :selected="currentRegion?.topographic_element_id" @select="selectRegion"
            label="Regioni" />
          <DropDown :options="insulaeDropdown" :selected="currentInsula?.topographic_element_id" @select="selectInsula"
            label="Insulae" />
          <div class="ref-top-list grid-col-span flex-col gap-5">
            <template v-for="(unity, i) in currentInsula?.unity ? currentInsula.unity : []" :key="i">
              <TheAccordion :index="i" :item="unity" :autoHeight="true" class="grid-col-span sub" @opened="selectUnity"
                :autoScroll="false" :isOpen="currentUnityIndex == i">
                <TheAccordion v-for="(enviroment, j) in currentUnityIndex == i ? unity.environments : []" :index="j"
                  :item="enviroment" :key="j" :autoScroll="false" @opened="selectEnvironment" :isOpen="currentEnvironmentIndex == j">
                  <div class="options-box flex-col" v-if="currentEnvironmentIndex == j">
                    <RadioButton v-model="topElId" :value="el.topographic_element_id"
                      :label="el.code + ' - ' + el.topographic_element4_type.name"
                      v-for="(el, i) in currentEnvironment.topographic_element4s" :key="i" />
                  </div>
                </TheAccordion>
              </TheAccordion>
            </template>
          </div>
        </div>
        <VLottie class="loading" v-if="loading" ref="animation" :src="require('@/assets/icons/loading.json')"
          :autoplay="true" :loop="true" />
        <div class="flex-col">
          <VMap v-if="regions.length > 0" :polygons="currentPolygons" :geolocate="!startingPosition" :customLayers="MAP_LAYERS"
            @location="filterByLocation" :orientation="true" :center="MAP_CENTER" />
          <div class="jc-end act-buttons gap-15">
            <VButton class="medium rounded" label="Cancella" @click="close" />
            <VButton class="medium rounded" label="Salva" @click="save" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import DropDown from './DropDown.vue';
import TheAccordion from './TheAccordion.vue';
import RadioButton from './RadioButton.vue';
import { onMounted, onUnmounted, ref } from 'vue'
import { useUXStore } from '@/stores/ux';

import { getNearestTopographicElement4 } from '@/services/topographicServices';
import { MAP_LAYERS, POLYGON_STYLES, MAP_CENTER, geojsonToTurf } from '@/utils/polygon';
import { getEnvironments, getInsulae, getRegions, getTopographicElements4, getUnities } from '@/services/cacheServices';

export default {
  name: 'RefTopPopUp',
  components: {
    DropDown,
    TheAccordion,
    RadioButton
  },
  props: {
    startingPosition: {
      type: Object,
      default: null,
    },
  },
  watch: {
    topElId(id) {
      this.selectTopographicElement(id)
    }
  },
  computed: {
    regionsDropdown() {
      return this.regions?.map(poly => {
        return {
          label: poly.name,
          value: poly.topographic_element_id,
        }
      })
    },
    insulaeDropdown() {
      return this.currentRegion?.insula?.map(insula => {
        return {
          label: insula.name,
          value: insula.topographic_element_id,
        }
      })
    },
    currentUnityIndex() {
      return this.currentInsula?.unity?.findIndex(unity => unity.topographic_element_id === this.currentUnity?.topographic_element_id)
    },
    currentEnvironmentIndex() {
      return this.currentUnity?.environments?.findIndex(environment => environment.topographic_element_id === this.currentEnvironment?.topographic_element_id)
    },
  },
  methods: {
    async selectRegion(region) {
      this.currentRegion = this.regions.find(r => r.topographic_element_id === region.value)

      // if no insulae, fetch them
      if (!this.currentRegion.insula) {
        this.currentRegion.insula = await getInsulae(this.currentRegion.topographic_element_id)
      }

      // set style for insulae
      this.currentRegion.insula.forEach(insula => {
        insula.style = POLYGON_STYLES.foreground

        insula.onClick = async () => {
          this.selectInsula({ value: insula.topographic_element_id })
        }
      })

      // set current region style, invisible polygon
      this.currentRegion.style = POLYGON_STYLES.transparent
      this.currentRegion.focus = true
      this.currentRegion.notInteractive = true

      // set other regions style
      this.regions.filter(r => r.topographic_element_id !== this.currentRegion.topographic_element_id).forEach(region => {
        region.style = POLYGON_STYLES.background
      })

      this.currentPolygons = [
        ...this.currentRegion.insula,
        this.currentRegion,
        //...this.regions.filter(r => r.topographic_element_id !== this.currentRegion.topographic_element_id) // disabled for now
      ]
      this.currentInsula = null
      this.currentUnity = null
      this.currentEnvironment = null
    },
    async selectInsula(insula) {
      this.currentInsula = this.currentRegion.insula.find(i => i.topographic_element_id === insula.value)

      if (!this.currentInsula.unity) {
        this.currentInsula.unity = await getUnities(this.currentInsula.topographic_element_id)
      }

      // set style for unity
      this.currentInsula.unity.forEach(unity => {
        unity.style = POLYGON_STYLES.foreground

        unity.onClick = async () => {
          await this.selectUnity(unity.topographic_element_id, 'id')
        }
      })

      // set current insula style, invisible polygon
      this.currentInsula.style = POLYGON_STYLES.transparent
      this.currentInsula.focus = true
      this.currentInsula.notInteractive = true

      // set other insulae style
      this.currentRegion.insula.filter(i => i.topographic_element_id !== this.currentInsula.topographic_element_id).forEach(insula => {
        insula.style = POLYGON_STYLES.background
      })

      this.currentPolygons = [
        ...this.currentInsula.unity,
        this.currentInsula,
        //...this.currentRegion.insula.filter(i => i.topographic_element_id !== this.currentInsula.topographic_element_id), // disabled for now
      ]

      this.currentUnity = null
      this.currentEnvironment = null
    },
    async selectUnity(unityId, parameterType) {
      let currentUnityIndex = null
      if (parameterType == 'id') {
        currentUnityIndex = this.currentInsula.unity.findIndex(unity => unity.topographic_element_id === unityId)
      } else {
        currentUnityIndex = unityId
      }

      this.currentUnity = { ...this.currentInsula.unity[currentUnityIndex] }

      console.log(this.currentUnity)

      if (!this.currentUnity.environments) {
        this.currentUnity.environments = await getEnvironments(this.currentUnity.topographic_element_id)

        // search for current unity in currentInsula and set environments
        this.currentInsula.unity = this.currentInsula.unity.map(unity => {
          if (unity.topographic_element_id === this.currentUnity.topographic_element_id) {
            unity.environments = this.currentUnity.environments
          }
          return unity
        })
      }

      // set style for environment
      this.currentUnity.environments = this.currentUnity.environments.map(environment => ({
        ...environment,
        style: POLYGON_STYLES.foreground,
        onClick: () => {
          this.selectEnvironment(environment.topographic_element_id, 'id')
        }
      }));

      // set current unity style, invisible polygon
      this.currentUnity = {
        ...this.currentUnity,
        style: POLYGON_STYLES.transparent,
        focus: true,
        notInteractive: true
      }

      // set other unity style
      this.currentInsula.unity = this.currentInsula.unity.map(unity =>
        unity.topographic_element_id !== this.currentUnity.topographic_element_id
          ? { ...unity, style: POLYGON_STYLES.background }
          : unity
      );

      this.currentPolygons = [
        ...this.currentUnity.environments,
        this.currentUnity,
        //...this.currentInsula.unity.filter(unity => unity.topographic_element_id !== this.currentUnity.topographic_element_id), // disabled for now
      ]
      this.currentEnvironment = null
    },
    async selectEnvironment(id, parameterType) {
      let selectedEnvironmentId = null
      let selectedEnvironmentIndex = null
      if (parameterType == 'id') {
        selectedEnvironmentId = id
        selectedEnvironmentIndex = this.currentUnity.environments.findIndex(environment => environment.topographic_element_id == selectedEnvironmentId);
      } else {
        selectedEnvironmentIndex = id
        selectedEnvironmentId = this.currentUnity.environments[selectedEnvironmentIndex].topographic_element_id
      }

      if (this.currentEnvironment?.topographic_element_id == selectedEnvironmentId) {
        return
      }

      this.currentEnvironment = { ...this.currentUnity.environments[selectedEnvironmentIndex] }

      if (!this.currentEnvironment.topographic_element4s) {
        this.currentEnvironment.topographic_element4s = await getTopographicElements4(this.currentEnvironment.topographic_element_id)
      }

      this.currentEnvironment.topographic_element4s = this.currentEnvironment.topographic_element4s.map(topographicElement4 => {
        return { ...topographicElement4, style: POLYGON_STYLES.foreground }
      })

      // set current environment style, invisible polygon
      this.currentEnvironment.style = POLYGON_STYLES.transparent

      // set other environments style
      this.currentUnity.environments.filter(environment => environment.topographic_element_id !== this.currentEnvironment.topographic_element_id).forEach(environment => {
        environment.style = POLYGON_STYLES.background
      })

      this.currentPolygons = [
        this.currentEnvironment,
        ...this.currentEnvironment.topographic_element4s
      ]
    },

    selectTopographicElement(topographicElementId) {
      if (typeof topographicElementId === 'object') {
        this.selectedTopographicElementId = topographicElementId.target.value
      } else {
        this.selectedTopographicElementId = topographicElementId
      }

      let topographicElement4 = this.currentEnvironment.topographic_element4s.find(element => element.topographic_element_id == this.selectedTopographicElementId)


      this.currentEnvironment.topographic_element4s.forEach(element => {
        element.style = POLYGON_STYLES.foreground
      })
      this.currentEnvironment.style = POLYGON_STYLES.transparent
      this.currentEnvironment.focus = true

      topographicElement4.style = POLYGON_STYLES.selected

      this.currentUnity.environments.forEach(environment => {
        environment.style = POLYGON_STYLES.background
      })

      this.currentPolygons = [
        topographicElement4,
        this.currentEnvironment,
        //...this.currentUnity.environments.filter(environment => environment.topographic_element_id !== this.currentEnvironment.topographic_element_id), // disabled for now
      ]
    },
    async filterByLocation(position) {
      this.currentPosition = position
      await this.calculatePolygons()
    },
    async calculatePolygons() {
      let result = []

      let availableUserPosition = this.startingPosition ? this.startingPosition : this.currentPosition

      const startingPosition = availableUserPosition ? geojsonToTurf(this.currentPosition) : null

      if (availableUserPosition) {
        let nearestTopographicElement4 = await getNearestTopographicElement4(startingPosition.geometry.coordinates[0], startingPosition.geometry.coordinates[1]);

        if (nearestTopographicElement4) {
          await this.selectRegion({ value: nearestTopographicElement4.topographic_element_id })
          await this.selectInsula({ value: nearestTopographicElement4.insula.topographic_element_id })
          await this.selectUnity(nearestTopographicElement4.insula.unity.topographic_element_id, 'id')
          await this.selectEnvironment(nearestTopographicElement4.insula.unity.environment.topographic_element_id, 'id')
          // await this.selectTopographicElement(nearestTopographicElement4.insula.unity.environment.topographic_element4s[0].topographic_element_id)
        }

        // add to result
        result = nearestTopographicElement4?.insula.unity.environment.topographic_element4s.map(topographicElement4 => {
          return geojsonToTurf(topographicElement4.topographic_element.transformed_polygon)
        })
      } else {
        // if no position is available, return all the polygons
        result = this.polygons
      }

      this.currentPolygons = result
    }
  },
  emits: ['close', 'save'],
  setup(props, { emit }) {
    const loading = ref(true)
    const searchQuery = ref('')
    const currentPosition = ref(null)
    const currentRegion = ref(null)
    const currentInsula = ref(null)
    const currentUnity = ref(null)
    const currentEnvironment = ref(null)
    const selectedTopographicElementId = ref(null)
    const polygons = ref(null)
    const uxstore = useUXStore()
    const topElId = ref()

    // different topographic elements
    const regions = ref([])

    const currentPolygons = ref([])

    const close = () => {
      emit('close')
    }

    const save = () => {
      let topographicElement = currentEnvironment.value.topographic_element4s.find(element => element.topographic_element_id == topElId.value)
      topographicElement.environment = currentUnity.value.environments.find(environment => environment.topographic_element_id == topographicElement.environment_id)
      topographicElement.environment.unity = currentInsula.value.unity.find(unity => unity.topographic_element_id == topographicElement.environment.unity_id)
      topographicElement.environment.unity.insula = currentRegion.value.insula.find(insula => insula.topographic_element_id == topographicElement.environment.unity.insula_id)
      topographicElement.environment.unity.insula.region = regions.value.find(region => region.topographic_element_id == topographicElement.environment.unity.insula.region_id)

      // simplify the object setting to null some attributes
      topographicElement.environment.topographic_element4s = null
      topographicElement.environment.unity.environments = null
      topographicElement.environment.unity.insula.unity = null
      topographicElement.environment.unity.insula.region.insula = null

      emit('save', topographicElement)
    }

    onMounted(async () => {
      uxstore.showOverlay = true
      regions.value = await getRegions()
      loading.value = false
    })

    onUnmounted(() => {
      uxstore.showOverlay = false

      currentPosition.value = null;
      currentRegion.value = null;
      currentInsula.value = null;
      currentUnity.value = null;
      currentEnvironment.value = null;
      selectedTopographicElementId.value = null;
      polygons.value = null;
      regions.value = [];
      currentPolygons.value = [];
    })

    return {
      close, save, polygons, loading, searchQuery, props, selectedTopographicElementId,
      currentPosition, regions, currentPolygons, MAP_LAYERS, MAP_CENTER,
      currentRegion, currentInsula, currentUnity, currentEnvironment, topElId
    }
  }
}
</script>

<style>
.ref-top {
  height: 100%;
  width: 100%;
  position: absolute;
}

.ref-top .popup {
  position: relative;
  background-color: var(--primary-color);
  height: 80%;
  width: 80%;
  padding: 30px;
  z-index: 5;
}

.ref-top .popup>div:last-child {
  height: 100%;
  overflow: hidden;
}

.ref-top .popup>div:last-child>div:first-child {
  grid-template-rows: max-content max-content;
  overflow: hidden;
}

.ref-top .popup .act-buttons {
  margin: 30px 0 10px 0;
}

.ref-top .popup span.title {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 0.875rem;
  letter-spacing: 0.035rem;
}

.ref-top .popup .act-buttons .v-button {
  background-color: var(--base-dark-color);
  color: var(--primary-color);
  justify-content: center;
  width: 100px;
}

.ref-top-list {
  overflow-y: auto;
  height: 100%;
}

.accordion.sub .panel {
  padding-left: 15px;
  display: flex;
  flex-direction: column;
  gap: 5px
}

.accordion:not(.sub) :is(.title, .panel) {
  background-color: #D9D9D9;
  display: flex;
}

.accordion:not(.sub) .panel {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.options-box {
  margin: 10px;
  gap: 7px;
}

.options-box .radiobutton {
  height: 20px;
}

.ref-top .popup .dropdown :is(.selected, .box) {
  width: 100%;
}

.ref-top-option {
  text-align: left;
  display: grid;
  grid-template-columns: 1em 1fr;
}
</style>