<template>
  <div class="sort" @click="sort">
    <VIcon :src="require('@/assets/icons/sort-' + icon + '.svg')" :size="7" />
    <span>{{ label }}</span>
  </div>
</template>
<script setup>
import { ref, defineProps, defineEmits, onMounted } from 'vue'

const props = defineProps({
  label: {
    type: String,
    default: ''
  },
  name: {
    type: String,
    default: ''
  }
})

const emit = defineEmits(['sort', 'interface'])

const ORDERINGS = {
  ASC: 'asc',
  DESC: 'desc',
  NONE: 'none'
}

let position = ORDERINGS.NONE

const icon = ref(ORDERINGS.NONE)

const sort = () => {
  switch (position) {
    case ORDERINGS.NONE:
      icon.value = ORDERINGS.ASC
      position = ORDERINGS.ASC
      break;
    case ORDERINGS.ASC:
      icon.value = ORDERINGS.DESC
      position = ORDERINGS.DESC
      break;
    case ORDERINGS.DESC:
      icon.value = ORDERINGS.NONE
      position = ORDERINGS.NONE
      break;
  }
  emit('sort', props.name, position)
}

// function that is callable from the parent component
const reset = () => {
  icon.value = ORDERINGS.NONE
  position = ORDERINGS.NONE
}

const emitInterface = () => {
  emit('interface', {
    reset: () => reset()
  })
}

onMounted(() => {
  emitInterface()
})
</script>
<style scoped>
.sort {
  gap: 3px;
  margin-bottom: 5px;
  user-select: none;
  cursor: pointer;
  margin-right: auto;
}

.sort span {
  white-space: nowrap;
}
.sort:hover span {
  text-decoration: underline;
}

.sort:hover .v-icon {
  opacity: 1;
}

.v-icon {
  opacity: 0.8;
}
</style>