import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import LoginView from '@/views/LoginView.vue'
import { useUXStore } from '@/stores/ux'

import ShowMonitoraggioPuntuale from '@/components/show/ShowMonitoraggioPuntuale.vue'
import ShowMonitoraggioSpeditivo from '@/components/show/ShowMonitoraggioSpeditivo.vue'
import ShowMonitoraggioOrizzontale from '@/components/show/ShowMonitoraggioOrizzontale.vue'
import MonitoraggioPuntualeForm from '@/components/form/MonitoraggioPuntualeForm.vue'
import MonitoraggioSpeditivoForm from '@/components/form/MonitoraggioSpeditivoForm.vue'
import MonitoraggioOrizzontaleForm from '@/components/form/MonitoraggioOrizzontaleForm.vue'
import { useAuthStore } from '@/stores/auth'

const routes = [
  {
    path: '/',
    name: 'home',
    redirect: '/manutenzione',
    meta: {
      rightMenu: 'RightMenuMap',
    },
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView,
    meta: {
      rightMenu: false
    },
  },
  // crud routes
  {
    path: '/manutenzione',
    name: 'manutenzione',
    component: HomeView,
    meta: {
      rightMenu: 'RightMenuMap'
    },
  },
  {
    path: '/manutenzione/monitoraggio/speditivo/:id',
    name: 'monitoraggio-speditivo',
    component: MonitoraggioSpeditivoForm,
    meta: {
      rightMenu: false,
      onClosePath: '/manutenzione/'
    },
  },
  {
    path: '/manutenzione/monitoraggio/speditivo/:id/mostra',
    name: 'mostra-monitoraggio-speditivo',
    component: ShowMonitoraggioSpeditivo,
    meta: {
      rightMenu: false,
      onClosePath: '/manutenzione/'
    },
  },
  {
    path: '/manutenzione/monitoraggio/orizzontale/:id',
    name: 'monitoraggio-orizzontale',
    component: MonitoraggioOrizzontaleForm,
    meta: {
      rightMenu: false,
      onClosePath: '/manutenzione/'
    },
  },
  {
    path: '/manutenzione/monitoraggio/orizzontale/nuovo',
    name: 'nuovo-monitoraggio-orizzontale',
    component: MonitoraggioOrizzontaleForm,
    meta: {
      rightMenu: false,
      onClosePath: '/manutenzione/'
    }
  },
  {
    path: '/manutenzione/monitoraggio/orizzontale/:id/mostra',
    name: 'mostra-monitoraggio-orizzontale',
    component: ShowMonitoraggioOrizzontale,
    meta: {
      rightMenu: false,
      onClosePath: '/manutenzione/'
    },
  },
  {
    path: '/manutenzione/monitoraggio/puntuale/nuovo',
    name: 'nuovo-monitoraggio-puntuale',
    component: MonitoraggioPuntualeForm,
    meta: {
      rightMenu: false,
      onClosePath: '/manutenzione/'
    }
  },
  {
    path: '/manutenzione/monitoraggio/puntuale/:id/mostra',
    name: 'mostra-monitoraggio-puntuale',
    component: ShowMonitoraggioPuntuale,
    meta: {
      rightMenu: false,
      onClosePath: '/manutenzione/'
    },
  },
  {
    path: '/manutenzione/monitoraggio/puntuale/:id',
    name: 'monitoraggio-puntuale',
    component: MonitoraggioPuntualeForm,
    meta: {
      rightMenu: false,
      onClosePath: '/manutenzione/'
    },
  },
  {
    path: '/manutenzione/monitoraggio/puntuale/approfondisci/:monitoringId',
    name: 'monitoraggio-puntuale-approfondisci',
    component: MonitoraggioPuntualeForm,
    meta: {
      rightMenu: false,
      onClosePath: '/manutenzione/'
    }
  },
  {
    path: '/cartografia',
    name: 'map',
    component: () => import('../views/MapView.vue'),
    meta: {
      rightMenu: 'MapFilter'
    },
  },
  {
    path: '/gestione',
    name: 'gestione',
    component: () => import('../views/GestioneView.vue'),
    meta: {
      rightMenu: false
    },
  },
  // catch all
  {
    path: '/:catchAll(.*)',
    name: 'not-found',
    component: () => import('../views/NotFoundView.vue'),
    meta: {
      rightMenu: false
    },
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes
})


router.afterEach((to) => {
  const uxStore = useUXStore()

  // decide which component to mount in the right menu
  // it is here because the components must be mounted only after the navigation
  if (to.meta.rightMenu === false) {
    uxStore.hideRightMenu()
  } else {
    uxStore.showRightMenu()
    uxStore.rightMenu.dynamicComponent = to.meta.rightMenu
  }

  // show or hide menus
  if (to.name === 'login') uxStore.mountAllMenu()
  else uxStore.unmountAllMenu();
})

export default router

router.beforeEach((to, from, next) => {
  const authStore = useAuthStore()
  if (to.path === '/' && authStore.isAuthenticated) {
    next();
  } else if (to.name !== 'login' && !authStore.isAuthenticated) {
    next({
      name: 'login',
      query: {
        redirectTo: to.fullPath,
      },
    });
  } else if (to.name === 'login' && authStore.isAuthenticated) {
    next({ name: 'manutenzione' });
  } else {
    next();
  }
});