<template>
  <div id="gallery" class="gap-10">
    <a v-for="(image, key) in images" :key="key" :href="image.path" :data-pswp-width="image.width"
      :data-pswp-height="image.height" data-cropped="false" target="_blank" rel="noreferrer"
      :data-drawing-src="image.drawing_path" data-pswp-type="image">
      <img :src="image.preview_path" alt="" />
    </a>
  </div>
</template>
<script setup>
import { ref, defineProps, onMounted } from 'vue'
import PhotoSwipeLightbox from 'photoswipe/lightbox';
import PhotoSwipe from 'photoswipe'

defineProps(['images']);
const showDrawing = ref(true);

const lightbox = new PhotoSwipeLightbox({
  gallery: '#gallery',
  children: 'a',
  zoom: false,
  maxZoomLevel: 3,
  padding: { top: 60, bottom: 60, left: 70, right: 70 },
  arrowPrevSVG: '<svg class="pswp__icn" xmlns="http://www.w3.org/2000/svg" width="11.29mm" height="11.29mm" viewBox="0 0 32 32"><polyline points="19.46 8.87 12.4 15.93 19.46 22.99" style="fill:none; stroke:#fff; stroke-linecap:round; stroke-linejoin:round; stroke-width:1.4px;"/></svg>',
  arrowNextSVG: '<svg class="pswp__icn" xmlns="http://www.w3.org/2000/svg" width="11.29mm" height="11.29mm" viewBox="0 0 32 32"><polyline points="19.46 8.87 12.4 15.93 19.46 22.99" style="fill:none; stroke:#fff; stroke-linecap:round; stroke-linejoin:round; stroke-width:1.4px;"/></svg>',
  closeSVG: '<svg aria-hidden="true" class="pswp__icn" xmlns="http://www.w3.org/2000/svg" width="11.29mm" height="11.29mm" viewBox="0 0 32 32"><use class="pswp__icn-shadow" xlink:href="#pswp__icn-close"></use><g id="pswp__icn-close"><line x1="9.27" y1="8.91" x2="23.38" y2="23.02" style="fill:none; stroke:#fff; stroke-linecap:round; stroke-linejoin:round; stroke-width:1.4px;" /><line x1="23.38" y1="8.91" x2="9.27" y2="23.02" style="fill:none; stroke:#fff; stroke-linecap:round; stroke-linejoin:round; stroke-width:1.4px;" /></g></svg>',
  pswpModule: PhotoSwipe
});

// parse data-drawings-src attribute
lightbox.addFilter('itemData', (itemData) => {
  const drawingSrc = itemData.element.dataset.drawingSrc;
  if (drawingSrc) {
    itemData.drawingSrc = drawingSrc;
  }
  return itemData;
});

lightbox.on('contentLoad', (e) => {
  const { content } = e;
  if (content.data.drawingSrc) {
    e.preventDefault();

    content.element = document.createElement('div');
    content.element.className = 'pswp__image-container pswp__img';

    const image = document.createElement('img');
    const drawing = document.createElement('img');
    drawing.className = 'pswp__draw'
    image.src = content.data.src;
    drawing.src = content.data.drawingSrc;
    content.element.appendChild(image);
    content.element.appendChild(drawing);

    if (content.element.firstChild.complete) {
      content.onLoaded();
    } else {
      content.element.firstChild.onload = () => { content.onLoaded(); };
      content.element.firstChild.onerror = () => { content.onError(); };
    }
  }
});

// Hide show/hide button if there is no drawing
lightbox.on('contentActivate', ({ content }) => {
  let drawButton = document.querySelector('.pswp__button--drawing-button');
  if (content.data.drawingSrc) {
    drawButton.style.display = 'unset';
    content.onLoaded();
  } else {
    drawButton.style.display = 'none';
  } 
})

// RESIGTER BUTTONS
lightbox.on('uiRegister', function () {

  // Toggle drawing button
  lightbox.pswp.ui.registerElement({
    name: 'drawing-button',
    title: 'Show/Hide drawing',
    order: 9,
    isButton: true,
    html: '<svg class="pswp__icn" xmlns="http://www.w3.org/2000/svg" width="11.29mm" height="11.29mm" viewBox="0 0 32 32"><g><path d="m20.01,15.47c-.12.11-.25.23-.37.34.27-.26.47-.44.37-.34Z"/><path d="m16.61,12.39c.05-.05.11-.1.16-.15-.15.14-.24.22-.16.15Z"/><path d="m19.36,11.47s0,0,0,0c.04-.04.05-.05,0,0Z"/><path d="m24.64,9.54c-.26-.78-.81-1.26-1.6-1.49-.12-.03-.24-.06-.36-.09h-.63s-.03.02-.04.02c-.44.07-.84.21-1.17.51-.19.17-.39.33-.57.5-.47.44-.94.88-1.41,1.32-.28.26-.56.51-.83.77-.42.39-.83.78-1.25,1.17.15-.14.38-.35.6-.56-.25.23-.5.46-.75.7-.39.37-.79.74-1.18,1.12-.16.15-.33.3-.49.46-.1.1-.21.21-.26.33-.11.23-.19.47-.27.71-.21.59-.41,1.17-.62,1.76-.08.24,0,.31-.09.54-.09.25-.19.5-.02.75.18.25.47.32.8.22.57-.18,1.54-.36,1.54-.36.53-.16,1.06-.34,1.59-.49.28-.08.55-.18.75-.42.06-.07.15-.13.22-.19.35-.32.7-.65,1.05-.97-.08.07-.16.15-.24.23.2-.19.41-.38.61-.57.29-.27.57-.53.86-.79.46-.43.93-.86,1.39-1.3.39-.37.79-.74,1.18-1.12.16-.15.31-.3.48-.45.35-.3.61-.64.74-1.09.03-.1.05-.2.07-.3v-.56c-.03-.11-.05-.22-.09-.33Zm-1.59,1.45c-.19.14-.35.32-.53.48-.4.37-.79.75-1.19,1.13-.46.43-.92.86-1.38,1.29-.29.27-.58.54-.87.81-.46.43,1.32-1.23.86-.79-.29.27-.57.53-.86.79-.47.44-.94.86-1.4,1.31-.26.26-.59.34-.91.44-.54.17-1.07.33-1.61.49-.03,0-.06.02-.15.04.13-.35.25-.67.36-.98.02-.04.04-.09.05-.13.06-.18.14-.35.18-.53.09-.45.36-.76.71-1.03.17-.13.31-.29.47-.43.4-.38.79-.75,1.19-1.13.45-.42.91-.84,1.36-1.27-.18.17-1.13,1.05-1.33,1.24.45-.42.9-.84,1.35-1.26.29-.27.59-.54.88-.82.44-.41.87-.83,1.33-1.23.65-.57,1.52-.29,1.8.27.22.44.08,1-.32,1.3Z"/></g><path d="m14.46,16.56c-.93,0-1.87-.03-2.8-.02-.5,0-1.06-.09-1.53.11-.43.17-.79.51-.98.93-.47.99-.12,2.51.98,2.94.57.22,1.27.11,1.87.1,1.48,0,2.96-.02,4.44-.02.87,0,1.57-.2,1.57.78,0,.68.49,1.24,1.09,1.24h4.71" style="fill:none; stroke:#fff; stroke-linecap:round; stroke-linejoin:round;"/></svg>',
    onClick: () => {
      toggleDrawing()
    }
  });

  // Download button
  lightbox.pswp.ui.registerElement({
    name: 'download-button',
    order: 8,
    isButton: true,
    tagName: 'a',
    title: 'Download image',
    html: '<svg id="pswp__icn-download" class="pswp__icn" xmlns="http://www.w3.org/2000/svg" width="11.29mm" height="11.29mm" viewBox="0 0 32 32"><path d="m24,21.17c0-.08,0-.18-.03-.26-.09-.27-.37-.45-.64-.41-.31.04-.52.29-.53.6,0,.42,0,.83,0,1.25v.47s-13.6,0-13.6,0v-.48c0-.4,0-.79,0-1.18,0-.21-.07-.39-.19-.51-.11-.11-.25-.16-.4-.16h0c-.35,0-.6.28-.6.68,0,.64,0,1.29,0,1.93v.21c0,.51.22.73.73.73.27,0,.55,0,.82,0,.2,0,.4,0,.6,0h13.09c.53,0,.75-.22.75-.76v-.35c0-.59,0-1.18,0-1.77Z"/><path d="m9.92,15.1c1.64,1.55,3.29,3.09,4.93,4.64l.59.56c.22.2.39.29.56.29h0c.17,0,.35-.09.56-.29,1.84-1.72,3.68-3.45,5.51-5.17l.02-.02c.06-.06.12-.11.17-.18.12-.16.14-.35.07-.53-.07-.18-.24-.31-.44-.35-.29-.06-.49.08-.66.24-1.5,1.41-3,2.82-4.5,4.23-.04.04-.08.07-.13.11h0s0-9.91,0-9.91v-.06c0-.05,0-.1,0-.16-.02-.28-.26-.52-.56-.54-.27-.02-.56.17-.63.42-.02.08-.02.16-.02.25v.04c0,3.25,0,5.08,0,7.83v2.17h0s-.02-.02-.02-.03c-.04-.05-.08-.1-.12-.14-1.48-1.4-2.97-2.79-4.45-4.19l-.02-.02c-.06-.06-.13-.12-.21-.17-.08-.05-.17-.09-.26-.1-.15-.02-.31.02-.44.12-.14.1-.22.25-.24.39-.01.09,0,.18.04.26.06.12.16.22.24.3Z"/></svg>',
    onInit: (el, pswp) => {
      el.setAttribute('download', '');
      el.setAttribute('target', '_blank');
      el.setAttribute('rel', 'noopener');

      pswp.on('change', () => {
        el.href = pswp.currSlide.data.src;
      });
    }
  });

});

// Show error if could not load photo
lightbox.addFilter('contentErrorElement', (contentErrorElement, content) => {
  const el = document.createElement('div');
  el.className = 'pswp__error-msg';
  el.innerHTML = `<a href="${content.data.src}" target="_blank">Image #${content.slide.index + 1}</a> cannot be loaded</a>`;
  return el;
});

const toggleDrawing = () => {
  showDrawing.value = !showDrawing.value;
  for (const img of document.getElementsByClassName('pswp__draw')) {
    if (showDrawing.value) img.classList.remove('hide');
    else img.classList.add('hide');
  }
}

onMounted(() => {
  lightbox.init();
})
</script>
<style>
#gallery img {
  height: 120px;
  width: 120px;
  object-fit: cover;
  /* background-size: cover; */
  /* display: block; */
  /* position: relative; */
}

#gallery .draw {
  position: absolute;
}

#gallery .pswp-darwing-content {
  display: none;
}

.pswp__image-container {
  position: relative;
}

.pswp__image-container img {
  position: absolute;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.pswp__draw {
  z-index: 99;
  transition: opacity .15s ease;
}

.pswp__draw.hide {
  opacity: 0;
}

.pswp__error-msg {
  white-space: pre;
}

.pswp__error-msg a {
  color: #86b7d1;
}
</style>