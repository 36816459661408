<template>
  <ShowMonitoraggioTemplate v-if="monitoring" icon="quick" :color="'#' + monitoring.priority?.color"
    :title="monitoring.name" :subtitle="monitoring.monitoring.topographic_element4?.code" :date="monitoring.date"
    :time="monitoring.time">

    <template #content>
      <div class="flex-col gap-5">
        <p><strong>Tipo di monitoraggio: </strong>Speditivo</p>
        <p><strong>REFERENTE TOPOGRAFICO: </strong>{{ monitoring.monitoring.topographic_element4?.name }}</p>
        <p><strong>PROGETTO DI RIFERIMENTO: </strong>{{ monitoring.project.name }}</p>
        <p><strong>OSSERVAZIONE: </strong>{{ monitoring.observations }}</p>
        <p><strong>SEGNALATORE: </strong>{{ monitoring.user ? monitoring.user.full_name : '-'
        }}</p>
      </div>
      <SheetGallery :images="monitoring.files" />
    </template>

    <template #map>
      <div class=" flex-col" v-if="monitoring.monitoring.topographic_element4">
        <p><strong>REGIONE: </strong>{{
          monitoring.monitoring.topographic_element4.environment.unity.insula.region.name }}</p>
        <p><strong>INSULA: </strong>{{ monitoring.monitoring.topographic_element4.environment.unity.insula.name }}
        </p>
        <p><strong>UNITÀ: </strong>{{ monitoring.monitoring.topographic_element4.environment.unity.name }}</p>
        <p><strong>AMBIENTE: </strong>{{ monitoring.monitoring.topographic_element4.environment.name }}</p>
      </div>
      <VMap :polygons="monitoring.monitoring.topographic_element4 ? [monitoring.monitoring.topographic_element4] : [{ polygon: monitoring.position }]"
      :customLayers="MAP_LAYERS" :currentLayer="0" />
    </template>

    <template #buttons>
      <VButton class="medium rounded action dark" label="Approfondisci"
        @click="router.push('/manutenzione/monitoraggio/puntuale/approfondisci/' + monitoring.monitoring.id)" />
      <VButton class="medium rounded action mid" label="Risolvi" />
    </template>
  </ShowMonitoraggioTemplate>
</template>

<script setup>
import { onMounted } from 'vue';
import { useRouter } from 'vue-router'
import ShowMonitoraggioTemplate from './ShowMonitoraggioTemplate.vue';
import SheetGallery from '../SheetGallery.vue';
import getMonitoring from '@/services/composables/getMonitoring';

const router = useRouter();
const { monitoring, load } = getMonitoring('quick-monitoring', router.currentRoute.value.params.id);

onMounted(() => {
  load()
});
</script>

<style>
.images {
  user-select: none;
  margin-top: 20px;
}

.image {
  height: 80px;
  width: 80px;
  object-fit: cover;
  cursor: pointer;
}
</style>