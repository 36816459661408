import useMonitoring, { punctualMonitoringDBParams } from "@/services/composables/useMonitoring";
import { openDB } from "idb";
import { defineStore } from "pinia";
import { reactive } from "vue";
import { notificationSettings, syncMonitorings } from "@/services/composables/useConnectionManager";

import { useToast } from "vue-toastification";


const toast = useToast();

export const useSettingsStore = defineStore("settings", () => {
    const { addMonitoring } = useMonitoring("punctual-monitoring");
    
    const storedSettings = JSON.parse(localStorage.getItem("settings")) || {};
    const settings = reactive({
        offlineMode : storedSettings.offlineMode || false,
    });

    function syncSettings() {
        localStorage.setItem("settings", JSON.stringify(settings));
    }

    function toggleOfflineMode() {
        settings.offlineMode = !settings.offlineMode;
        syncSettings();

        if (!settings.offlineMode) {
            openDB("punctual-monitoring", punctualMonitoringDBParams.version, punctualMonitoringDBParams).then(async (db) => {
                await syncMonitorings(db, addMonitoring);
            }).catch((err) => {
                console.log(err);
                toast(`Errore durante l'apertura del database: ${err.message}`, notificationSettings);
            });
        }
    }

    return { settings, toggleOfflineMode };
});