<template>
  <div class="radio-with-label ai-center">{{ label }}:
    <RadioButton v-model="selected" v-for="(opt, i) in options" :value="opt.value" :label="opt.label" :key="i" />
  </div>
</template>
<script setup>
import { ref, defineProps, defineEmits, watchEffect } from 'vue'
import RadioButton from './RadioButton.vue'

const props = defineProps(['options', 'modelValue', 'label'])
const emit = defineEmits(['update:modelValue'])

const selected = ref(props.modelValue)

watchEffect(() => {
  emit('update:modelValue', selected.value)
})

</script>

<style>
.radio-with-label {
  padding: 7px 10px;
  height: 42px;
  gap: 15px;
}
</style>