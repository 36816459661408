<template>
  <div class="autocomplete flex-col">
    <input type="text" @input="onChange" v-model="search" @keydown.down="onArrowDown" @keydown.up="onArrowUp"
      @keydown.enter.prevent @keydown.enter="onEnter" @keydown.escape="handleClickOutside" @focus="onChange"
      :readonly="props.createUrl === ''"
      @blur="handleClickOutside" :tabindex="-1" :disabled="disabled" />
    <input type="hidden" :value="selectedValue.value" :name="name" />
    <VButton v-if="selectedValue.value" @click="delSelected" :icon="require('@/assets/icons/cross.svg')" :iconSize="10" />
    <div id="autocomplete-results" v-show="isOpen" class="autocomplete-results flex-col">
      <div v-for="(result, i) in results" :key="i" @mousedown="setResult(result)" @mouseover="arrowCounter = i"
        @mouseleave="hover = 1" class="autocomplete-result" :class="{ 'is-active': i === arrowCounter }" ref="element">
        <p><strong>{{ (result.prefix ? result.prefix : '') }}</strong>{{ result.label }}</p>
        <VIcon v-show="i === arrowCounter" :src="require('@/assets/icons/enter-key.svg')" :size="15" />
      </div>
    </div>
  </div>
</template>
<script setup>
import axios from 'axios';
import { ref, defineProps, defineEmits, watch, computed } from 'vue'

const props = defineProps({
  options: {
    type: Array,
    required: false,
    default: () => []
  },
  name: {
    type: String,
    required: false,
    default: ""
  },
  disabled: {
    type: Boolean,
    required: false,
    default: false
  },
  createUrl: {
    type: String,
    required: false,
    default: ""
  },
  value: {
    type: [Number, String],
    required: false,
    default: ""
  }
})

// reactive data
const isOpen = ref(false)
const results = ref([])
const search = ref(props.options.find(option => option.value === props.value)?.label || "")
const arrowCounter = ref(1)
const element = ref()
const createdValues = ref([])


const emit = defineEmits(['input'])


// props.options + the created values
const computedOptions = computed(() => {
  if (!props.options) return []
  return props.options.concat(createdValues.value)
})

const onChange = () => {
  filterResults();
  isOpen.value = true;
  arrowCounter.value = 0;
}

const filterResults = () => {
  results.value = computedOptions.value.filter(item => {
    return item.label.toLowerCase().indexOf(search.value.toLowerCase()) > -1;
  });

  // if there are no results or if there is not an exact correspondence, let's give the possibility to create a new one
  if (results.value.length === 0 || results.value[0].label !== search.value && search.value) {
    if (props.createUrl) {
      results.value.push({
        prefix: "Crea: ",
        label: search.value,
        value: search.value,
        create: true
      });
    }
  }
}

const setResult = async (result) => {
  search.value = result.label;
  isOpen.value = false;

  if (result.create) {
    await axios.post(props.createUrl, {
      name: result.value
    }).then(response => {
      createdValues.value.push({
        label: response.data.name,
        value: response.data.id
      });
      search.value = result.label
    });
  }
  emit("input", selectedValue.value);
  arrowCounter.value = -1
}

const onEnter = () => {
  setResult(results.value[arrowCounter.value]);
}

const onArrowDown = () => {
  // if the arrowCounter is less than the length of the results array, increment it
  if (arrowCounter.value < results.value.length - 1) {
    arrowCounter.value = arrowCounter.value + 1;
    element.value[arrowCounter.value - 1].scrollIntoView()
  }
}

const onArrowUp = () => {
  if (arrowCounter.value > 0) {
    arrowCounter.value = arrowCounter.value - 1;
    element.value[arrowCounter.value].scrollIntoView()
  }
}

const handleClickOutside = () => {
  isOpen.value = false
  let option = computedOptions.value.find(item => {
    return item.label.toLowerCase() == search.value.toLowerCase();
  })
  if (option) {
    setResult(option)
  } else {
    search.value = ''
    emit('input', selectedValue.value)
  }
}

watch(() => props.options, (val, oldValue) => {
  if (val?.length !== oldValue?.length) {
    results.value = val;
    search.value = computedOptions.value.find(option => option.value === props.value)?.label || ""
  }
})

// watch the modelValue in order to update the search value
watch(() => props.value, (val, oldValue) => {
  if (val !== oldValue) {
    search.value = computedOptions.value.find(option => option.value === val)?.label || ""
  }
})

const selectedValue = computed(() => {
  let result = computedOptions.value.find(item => {
    return item.label === search.value;
  });

  if (!result) {
    result = { value: "" }
  }

  return result;
})

const delSelected = () => {
  search.value = ''
}
</script>
<style scoped>
.autocomplete {
  position: relative;
  width: 100%;
}

.autocomplete-results {
  position: absolute;
  padding: 0;
  margin: 0;
  background-color: #fefefe;
  border-radius: 10px;
  top: 22px;
  box-shadow: 0px 1px 6px -3px #000000;
  max-height: 240px;
  overflow: auto;
  width: 100%;
  z-index: 5;
}

.autocomplete-result {
  list-style: none;
  text-align: left;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
}

.autocomplete-result.is-active {
  background-color: var(--hover-color);
}

strong {
  text-transform: capitalize;
}

.autocomplete .v-button {
  position: absolute;
  right: 0;
  top: -4px;
  padding: 8px;
}
</style>