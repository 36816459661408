<template>
  <div>
    <VMap zoom-control layer-control ref="map" :fitBounds="false" :center="MAP_CENTER" :geolocate="true"
     :customLayers="MAP_LAYERS" :leafletConfig="LEAFLET_CONFIG" :polygons="uxStore.rightMenu.data.polygons ? uxStore.rightMenu.data.polygons : []" />
    <VButton class="rounded dark medium action" label="Entra" @click="router.push({ name: 'map' })" />
  </div>
</template>
<script setup>
import { useUXStore } from '@/stores/ux';
import { LEAFLET_CONFIG, MAP_CENTER, MAP_LAYERS } from '@/utils/polygon';
import { ref } from 'vue';
import { useRouter } from 'vue-router';

const map = ref(null);
const uxStore = useUXStore();

const router = useRouter();
</script>
<style scoped>
.v-button {
  position: absolute;
  top: 20px;
  right: 20px;
  padding: 0 15px;
}

div {
  height: 100%;
  width: 100%;
}
</style>
