import { defineStore } from 'pinia'
import { reactive, ref } from 'vue'

export const useUXStore = defineStore('counter', () => {

  const mainMenu = reactive({ open: false, show: true, mount: true })
  const rightMenu = reactive({
    open: false,
    show: true,
    mount: true,
    dynamicComponent: null,
    data : {}
  })
  const showOverlay = ref(false)

  function mountAllMenu() {
    mainMenu.value = true;
    rightMenu.value = true;
  }
  function unmountAllMenu() {
    mainMenu.mount = false;
    rightMenu.mount = false;
  }

  function hideRightMenu() {
    rightMenu.show = false;
  }

  function showRightMenu() {
    rightMenu.show = true;
  }

  function showMainMenu() {
    mainMenu.show = true;
  }

  return { mainMenu, rightMenu, showOverlay, mountAllMenu, unmountAllMenu, hideRightMenu, showRightMenu, showMainMenu }
})