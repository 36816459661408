<template>
  <label class="color-checkbox" @click="toggleCheckbox" @mouseenter="onMouseEnter" @mouseleave="onMouseLeave">
    <VLottie class="checkbox" ref="animation" :src="require('@/assets/icons/checkbox.json')" :style="customStyle" />
    {{ label }}
  </label>
</template>

<script setup>
import { ref, defineEmits, defineProps, computed, onMounted, watch } from 'vue'

const props = defineProps({
  "modelValue": { type: Boolean, default: false },
  "label": String,
  "boxColor": String,
  "markColor": { default: "white" }
})

const emit = defineEmits(["update:modelValue"])

// animation reference
const animation = ref(null)

// state of the checkbox
const state = ref(props.modelValue)

// updates the animation based on the state of the checkbox
const updateAnimation = () => {
  animation.value.setSpeed(2)
  const segments = state.value ? [15, 75] : [90, 136]
  animation.value.playSegments(segments, true)
}

// toggles the state of the checkbox
const toggleCheckbox = () => {
  state.value = !state.value
  emit('update:modelValue', state.value)
  updateAnimation()
}

// handlers for mouse enter and leave events
const onMouseEnter = () => { if (!state.value) animation.value.playSegments([136, 170], true) }
const onMouseLeave = () => { if (!state.value) animation.value.playSegments([150, 136], true) }

// styles for the checkbox
const customStyle = computed(() => ({
  '--box-color': props.boxColor,
  '--mark-color': props.markColor
}))

watch(() => props.modelValue, modelValue => {
  state.value = modelValue
  updateAnimation()
})

onMounted(() => {
  if (state.value) animation.value.playSegments([74, 75], true)
})
</script>

<style>
label.color-checkbox {
  display: flex;
  user-select: none;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 0.05em;
  gap: 5px;
  font-size: 0.8125rem;
  cursor: pointer;
  align-items: center;
}

.v-lottie.checkbox {
  height: 100%;
  width: 18px;
}

.v-lottie.checkbox .box path {
  fill: var(--box-color);
  stroke: var(--box-color);
}

.v-lottie.checkbox .mark path {
  stroke: var(--mark-color);
}

.v-lottie.checkbox svg {
  transform: unset !important;
}
</style>