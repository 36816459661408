<template>
  <div class="flex-col ai-end">
    <div v-if="!props.genericContent" class="item grid" @click="expandItem"
      :style="{ cursor: existDegradations ? 'pointer' : 'default' }">
      <!-- <div class="color-code" :style="{ backgroundColor: 'var(--alta-color)' }"></div> -->
      <VIcon class="jc-center ai-center" :src="require('@/assets/icons/' + item.monitoring_type + '.svg')"
        :style="{ backgroundColor: item.color ? '#' + item.color : 'var(--alta-color)' }" />
      <p>{{ item[item.monitoring_type + "MonitoringDate"] }}</p>
      <p>{{ item[item.monitoring_type + "MonitoringName"] }}</p>
      <p>{{ item.topographic_element_4_name }}</p>
      <div class="action-buttons">
        <VButton data-testid="show-button" :icon="require('@/assets/icons/m-eye.svg')"
          @click.stop="emit('show', props.item)" />
        <VButton data-testid="edit-button" :icon="require('@/assets/icons/m-edit.svg')"
          @click.stop="emit('edit', props.item)" />
        <VButton data-testid="link-button" :icon="require('@/assets/icons/m-geo-pin.svg')"
          @click.stop="emit('link', props.item)" />
      </div>
    </div>
    <div v-else class="item grid">
      <p></p>
      <p v-for="(value, key) in item" :key="key">{{ value }}</p>
    </div>
    <div v-if="existDegradations" class="item-modal flex-col" ref="content" :class="{ active: open }">
      <div class="item-modal-content grid grid-2-col gap-10">
        <template v-for="(item, i) in item.specificElements" :key="i">
          <p class="partition-name"><strong>{{ item.name }}: </strong></p>
          <div class="gap-10">
            <StatusTag :color="'#' + item.ia?.color" label="IA" :height="21" />
            <StatusTag :color="'#' + item.ifr?.color" label="IFR" :height="21" :title="item.ifr.name" />
          </div>
          <template v-for="(deg, index) in item.degradations" :key="index">
            <p class="degradation-name" :title="deg.annotations || 'Nessuna annotazione'">{{ deg.name }}</p>
            <p title="Difettosità Relativa">DR {{ deg.dr }}</p>
          </template>
        </template>
      </div>
    </div>
  </div>
</template>
<script setup>
import StatusTag from './StatusTag.vue'
import { ref, defineEmits, defineProps, watchEffect, computed } from 'vue'

const emit = defineEmits(['show', 'edit', 'link', 'expand'])
const props = defineProps({
  item: { type: Object, required: true },
  open: { type: Boolean, default: false },
  genericContent: { type: Boolean, default: false }
})
const content = ref(null);

const existDegradations = !props.genericContent ? computed(() => {
  if (!props.item.specificElements) return false;

  let is = props.item.specificElements.map(element => { return element.degradations.length ? true : false });
  return is.some(value => value === true);
}) : false;

const expandItem = () => {
  emit('expand')
  if (existDegradations.value) {
    console.log('c', content.value);
    if (content.value.style.maxHeight) {
      content.value.style.maxHeight = content.value.scrollHeight;
      setTimeout(function () { content.value.style.maxHeight = null; }, 50)
    } else {
      content.value.style.maxHeight = content.value.scrollHeight + "px";
      // setTimeout(function () { content.value.style.maxHeight = "unset"; }, 200)
    }
  }
}

watchEffect(() => {
  if (!props.open && content.value) {
    content.value.style.maxHeight = content.value.scrollHeight;
    setTimeout(function () { content.value.style.maxHeight = null; }, 50)
  }
})

</script>
<style scoped>
.item {
  grid-template-columns: 30px 90px 3fr 1fr 110px;
  color: var(--secondary-color);
  width: 100%;
  background-color: var(--base-light-color);
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  text-align: left;
  column-gap: 20px;
}

.item p {
  font-size: 1.2em;
  font-weight: 400;
  line-height: 22px;
  color: var(--secondary-color);
  white-space: nowrap;
}

.item .v-icon {
  height: 30px;
  width: 30px;
  border-radius: 50%;
}

.item-modal {
  max-height: 0;
  width: calc(100% - 20px);
  background-color: var(--base-light-color);
  overflow: hidden;
  transition: all .3s ease;
  /* overflow-y:  auto; */
}

.item-modal.active {
  margin-top: 5px;
}

.item-modal .item-modal-content {
  justify-items: self-start;
  align-items: center;
  padding: 15px 45px;
}

.item-modal * {
  opacity: 0;
  transition: all .2s ease;
}

.item-modal.active * {
  opacity: 1;
}

.item-modal .status-tag {
  padding: 0px 10px 0px 5px;
}

.item-modal .partition-name {
  margin: 5px 0;
}

/* .color-code {
  height: 20px;
  width: 20px;
  border-radius: 50%;
} */

.action-buttons {
  gap: 13px;
}

.action-buttons .plain-button {
  margin: 0;
}
</style>