<template>
  <div class="status-tag ai-center gap-5" :style="style">
    <div v-if="color" class="color" :style="{height: (height / 2.5) + 'px', width: (width ? width : height / 2.5) + 'px', backgroundColor: color}"></div>
    {{label}}
  </div>
</template>

<script setup>
import { computed, defineProps } from 'vue';

const props = defineProps(['label','height', 'width', 'border', 'color'])
const style = computed(() => {
  return {
    height: props.height + 'px', 
    width: props.width ? props.width + 'px' : 'auto', 
    border: props.border ? '1px solid #C8C8C8' : 'none', 
    paddingLeft: props.color ? '10px' : '20px'
  }
})
</script>

<style scoped>
.status-tag {
  border-radius: 50px;
  padding: 0 20px 0 10px;
  user-select: none;
  background-color: var(--primary-color);
  white-space: nowrap;
}

.status-tag .color {
  border-radius: 50%;
}
</style>
